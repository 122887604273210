import { useState } from 'react';

import { Typography } from '@destination/components';

export const EvseStatusYAxisTooltip = ({
  text,
  children,
  y,
  width
}: {
  text: string;
  children: React.ReactNode;
  y: number;
  width: number;
}) => {
  const [visible, setVisible] = useState(false);
  const [timer, setTimer] = useState<ReturnType<typeof setTimeout> | null>(
    null
  );

  const handleMouseEnter = () => {
    if (timer) {
      clearTimeout(timer);
    }
    setVisible(true);
  };

  const handleMouseLeave = () => {
    const newTimer = setTimeout(() => {
      setVisible(false);
    }, 200);
    setTimer(newTimer);
  };

  return (
    <g>
      <rect
        x={0}
        y={y - 20}
        width={width}
        height={30}
        fill="transparent"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      />
      {children}
      {visible && (
        <foreignObject
          x={0}
          y={y - 20}
          width={'100%'}
          height={'100%'}
          data-testid="tooltip"
        >
          <div className="fixed z-[9999] rounded-[4px] bg-white p-2.5 shadow-[0_0_15px_rgba(0,0,0,0.2)]">
            <div>
              <Typography className="whitespace-nowrap" variant="smallText">
                {text}
              </Typography>
            </div>
          </div>
        </foreignObject>
      )}
    </g>
  );
};
