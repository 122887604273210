import React from 'react';

import { Typography } from '@destination/components';

import {
  ChargerActiveSession,
  ConnectionStatus,
  ConnectionStatusEnum,
  EvseStatus,
  SocketStatus
} from '@omnis-pulse-types';

export interface IChargerCellContent {
  evseStatuses: EvseStatus[];
  render: (evse: EvseStatus, session?: ChargerActiveSession) => React.ReactNode;
  sessions?: ChargerActiveSession[];
  isFirstCell?: boolean;
  isLastCell?: boolean;
  connectionStatus: ConnectionStatus;
}

export const ChargerCellContent = ({
  evseStatuses,
  render,
  sessions,
  isFirstCell = false,
  isLastCell = false,
  connectionStatus
}: IChargerCellContent) => {
  const HorizontalSeparatorClasses = ['my-2 h-[1px] bg-[#EBEBEB]'];
  if (!isFirstCell) {
    HorizontalSeparatorClasses.push('-ml-8 rounded-sm');
  }
  if (isLastCell) {
    HorizontalSeparatorClasses.push('w-[calc(100% + 16px)]');
  } else {
    HorizontalSeparatorClasses.push('w-[calc(100% + 32px)]');
  }

  return (
    <div className="relative flex h-full flex-col justify-center">
      {isFirstCell && (
        <div
          data-testid="vertical-separator"
          className="absolute -left-2 h-full w-[1px] rounded-sm bg-[#EBEBEB]"
        />
      )}
      {connectionStatus.status === ConnectionStatusEnum.UNKNOWN ? (
        <Typography variant="smallText">--</Typography>
      ) : (
        <>
          {evseStatuses.map((evse, index) => (
            <React.Fragment key={evse.id}>
              {index > 0 && (
                <div
                  data-testid="horizontal-separator"
                  className={HorizontalSeparatorClasses.join(' ')}
                />
              )}
              {render(evse, getSession(sessions ?? [], evse.sockets))}
            </React.Fragment>
          ))}
        </>
      )}
    </div>
  );
};

const getSession = (
  sessions: ChargerActiveSession[],
  sockets: SocketStatus[]
) => {
  return sessions.find(({ connectorId }) =>
    sockets.some(socket => socket.id === connectorId)
  );
};
