import useSWR from 'swr';

import { GRID_METER_BY_ID_PATH } from '@api/paths';

import { GridMeter } from '@omnis-pulse-types';

export default function useGridMeter(gridMeterId?: string) {
  const { data, error, isLoading, mutate } = useSWR<GridMeter>(
    gridMeterId ? GRID_METER_BY_ID_PATH(gridMeterId) : null
  );

  return { gridMeter: data, isLoading, isError: Boolean(error), mutate };
}
