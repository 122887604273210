import { useEffect } from 'react';

import { usePortalTranslation } from '@hooks/use-portal-translation';
import { useNavigate, useParams } from 'react-router-dom';

import { GridMeterDetails } from '@components/grid-meters/GridMeterDetails';
import { TabLabel } from '@components/TabLabel';
import { useSiteDevicesContext } from '@context/SiteDevicesContext';

import { NothingConfigured, Typography } from '@destination/components';

export const GridMeters = () => {
  const { gridMeterId } = useParams();
  const navigate = useNavigate();
  const { t, t_generic } = usePortalTranslation('gridMeters');

  const { deviceData } = useSiteDevicesContext();

  useEffect(() => {
    if (!gridMeterId && deviceData.gridMeters?.length) {
      navigate(`${deviceData.gridMeters[0].id}`);
    }
  }, [deviceData.gridMeters, gridMeterId, navigate]);

  if (deviceData.gridMeters.length === 0) {
    return (
      <NothingConfigured
        title={t_generic('labels.no_configured', {
          entity: t_generic('literals.grid_meter_other')
        })}
        className="my-auto"
        icon={null}
      >
        <Typography
          className="tw-max-w-[263px] tw-text-center"
          variant="description"
        >
          {t('errors.no_configured_message')}
        </Typography>
      </NothingConfigured>
    );
  }

  const gridMeterName =
    deviceData.gridMeters.find(gridMeter => gridMeter.id === gridMeterId)
      ?.name ?? t_generic('literals.grid_meter', { titleize: true });

  return (
    <>
      <TabLabel label={gridMeterName} />
      <GridMeterDetails gridMeters={deviceData.gridMeters} />
    </>
  );
};
