import useSWR from 'swr';

import { SITE_BY_ID_PATH } from '@api/paths';

import { SitePeakCapacity } from '@omnis-pulse-types';

export default function useSiteEnergyManagementPeakCapacity(siteId?: string) {
  const { data, error, isLoading, mutate } = useSWR<SitePeakCapacity>(
    siteId ? `${SITE_BY_ID_PATH(siteId)}/energy-management/peak-capacity` : null
  );

  const isNotFound = error?.status === 404;

  if (isNotFound) {
    return {
      data: undefined,
      isLoading: false,
      isError: false,
      isNotFound,
      mutate
    };
  }

  return {
    data,
    isLoading,
    isError: Boolean(error),
    isNotFound,
    mutate
  };
}
