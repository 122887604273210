import { useDecodedToken } from '@hooks/use-decoded-token';
import { useTranslation } from 'react-i18next';

import useUserDetails from '@api/user-account/use-user-details';

import { LoadingSkeleton, Typography } from '@destination/components';

import { OrganizationSelect } from './OrganizationSelect';

export const UserDetails = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'general' });

  const { decodedToken } = useDecodedToken();
  const { userDetails, isLoading, isError } = useUserDetails();

  const renderPage = (content: React.ReactNode) => (
    <div className="flex flex-col justify-end text-end">
      {decodedToken && (
        <Typography variant="smallText" className="mt-auto pr-[22px] font-bold">
          {decodedToken.name}
        </Typography>
      )}
      {content}
    </div>
  );

  if (isError) {
    return renderPage(
      <Typography variant="smallText" className="mt-auto">
        {t('could_not_load_organization_name')}
      </Typography>
    );
  }

  if (isLoading) {
    return renderPage(
      <LoadingSkeleton isDarker height={16} className="w-[120px]" />
    );
  }

  return renderPage(userDetails && <OrganizationSelect />);
};
