import { useTranslation } from 'react-i18next';

import useUserNotificationSettings from '@api/user-account/use-user-notification-settings';
import { PageHeader } from '@components/general/PageHeader';
import { TabLabel } from '@components/TabLabel';
import NotificationIcon from '@icons/notification_16.svg';

import {
  DataError,
  Grid,
  LoadingSkeleton,
  NothingConfigured,
  Typography
} from '@destination/components';

import { NotificationTypeBox } from './NotificationTypeBox';

export const Notifications = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'notifications' });
  const { userNotificationSettings, isLoading, isError, mutate } =
    useUserNotificationSettings();

  const thereAreNotificationTypes = Boolean(
    Array.isArray(userNotificationSettings?.types) &&
      userNotificationSettings.types.length > 0
  );

  const renderPage = (content: React.ReactNode) => (
    <>
      <TabLabel label={t('page_title')} />
      <PageHeader title={t('page_title')} />
      {content}
    </>
  );

  if (isError) {
    return renderPage(
      <div className="mt-32">
        <DataError
          variant="primary"
          onRefresh={() => mutate(undefined, true)}
        />
      </div>
    );
  }

  if (isLoading) {
    return renderPage(
      <LoadingSkeleton isDarker className="!h-full rounded-[10px]" height={0} />
    );
  }

  if (!isLoading && !thereAreNotificationTypes) {
    return renderPage(
      <div className="mt-32">
        <NothingConfigured
          title={t('no_configured')}
          icon={
            <div className="h-24 w-24">
              <NotificationIcon className="h-full w-full" />
            </div>
          }
        />
      </div>
    );
  }

  return renderPage(
    <>
      <Typography variant="description" className="mb-6">
        {t('description')}
      </Typography>
      <Grid>
        {userNotificationSettings?.types.map(type => (
          <NotificationTypeBox key={type.name} type={type} />
        ))}
      </Grid>
    </>
  );
};
