import { usePortalTranslation } from '@hooks/use-portal-translation';
import { Link } from 'react-router-dom';

import CheckIcon from '@icons/check_16.svg';
import OfflineIcon from '@icons/offline_16.svg';
import { dateTimeFormat, formatDate } from '@utils/date-utils';

import { Grid } from '@destination/components';

import { Charger, ConnectionStatusEnum } from '@omnis-pulse-types';

export interface IConnectedChargersTableRow {
  charger: Charger;
  index: number;
}

export const ConnectedChargersTableRow = ({
  charger,
  index
}: IConnectedChargersTableRow) => {
  return (
    <Grid.TableRow
      data-testid="gateway-charger-table-row"
      alternateRowColor={index % 2 !== 0}
    >
      <Grid.DataCell className="break-all">
        <Link
          to={`../../chargers/${charger.id}`}
          className="decoration-2 hover:underline"
        >
          {charger.name}
        </Link>
      </Grid.DataCell>
      <Grid.DataCell>{charger.serialNumber}</Grid.DataCell>
      <ConnectionDataCell status={charger.connectionStatus.status} />
      <Grid.DataCell className="text-nowrap">
        {formatDate(charger.connectionStatus.timestamp, dateTimeFormat)}
      </Grid.DataCell>
    </Grid.TableRow>
  );
};

const ConnectionDataCell = ({ status }: { status: ConnectionStatusEnum }) => {
  const { t_generic } = usePortalTranslation('');

  const statusLabels = {
    [ConnectionStatusEnum.CONNECTED]: t_generic('states.connected', {
      titleize: true
    }),
    [ConnectionStatusEnum.NOT_CONNECTED]: t_generic('states.disconnected', {
      titleize: true
    }),
    [ConnectionStatusEnum.UNKNOWN]: t_generic('states.unknown', {
      titleize: true
    }),
    [ConnectionStatusEnum.FAILED_TO_CONNECT]: t_generic(
      'states.failed_to_connect',
      { titleize: true }
    ),
    [ConnectionStatusEnum.CONNECTING]: t_generic('states.unknown', {
      titleize: true
    })
  };

  const statusIcons = {
    [ConnectionStatusEnum.CONNECTED]: (
      <CheckIcon data-testid="check-icon" className="text-[#0CA919]" />
    ),
    [ConnectionStatusEnum.NOT_CONNECTED]: (
      <OfflineIcon data-testid="disconnected-icon" className="text-[#F03040]" />
    ),
    [ConnectionStatusEnum.UNKNOWN]: (
      <OfflineIcon data-testid="unknown-icon" className="text-[#BABABA]" />
    ),
    [ConnectionStatusEnum.FAILED_TO_CONNECT]: (
      <OfflineIcon data-testid="unknown-icon" className="text-[#BABABA]" />
    ),
    [ConnectionStatusEnum.CONNECTING]: (
      <OfflineIcon data-testid="unknown-icon" className="text-[#BABABA]" />
    )
  };

  return (
    <Grid.DataCell>
      <div className="flex items-center gap-2">
        {statusIcons[status]}
        <span
          className={
            status === ConnectionStatusEnum.CONNECTED ? '' : 'font-bold'
          }
        >
          {statusLabels[status]}
        </span>
      </div>
    </Grid.DataCell>
  );
};
