import { getSocketTypeIcons } from '@utils/socket-utils';

import { SocketStatus, SocketStatusEnum } from '@omnis-pulse-types';

export const SocketTypeCell = ({ sockets }: { sockets: SocketStatus[] }) => {
  const isASocketCharging = sockets.some(
    socket => socket.status === SocketStatusEnum.CHARGING
  );
  const isASocketInError = sockets.some(
    socket => socket.status === SocketStatusEnum.FAULT
  );

  return (
    <span className="flex items-center justify-center gap-[12px] py-[1px]">
      {sockets.map(socket => {
        const isThisSocketCharging =
          socket.status === SocketStatusEnum.CHARGING;

        let colorClass = '#000000';

        if (isASocketInError) {
          colorClass = '#ff010f';
        } else if (isASocketCharging && isThisSocketCharging) {
          colorClass = '#0052FF';
        }

        return (
          <div
            key={socket.id}
            className={`h-4 w-4 ${isASocketCharging && !isThisSocketCharging && 'opacity-50'}`}
          >
            {getSocketTypeIcons(socket.type, colorClass)}
          </div>
        );
      })}
    </span>
  );
};
