import { isWithinInterval } from 'date-fns';

export const getDifferenceBetweenDates = (
  endDate: number,
  startDate: number
) => {
  const diffTime = Math.abs(endDate - startDate);
  let days = diffTime / (24 * 60 * 60 * 1000);
  let hours = (days % 1) * 24;
  let minutes = (hours % 1) * 60;
  let secs = (minutes % 1) * 60;
  [days, hours, minutes, secs] = [
    Math.floor(days),
    Math.floor(hours),
    Math.floor(minutes),
    Math.floor(secs)
  ];

  return `${hours < 10 ? '0' + hours : hours}:${
    minutes < 10 ? '0' + minutes : minutes
  }:${secs < 10 ? '0' + secs : secs}`;
};

export const formatDurationInHoursAndMinutes = (
  endDate: number,
  startDate: number
) => {
  const diffTime = Math.abs(endDate - startDate);

  const hours = Math.floor(diffTime / (1000 * 60 * 60));
  const minutes = Math.floor((diffTime % (1000 * 60 * 60)) / (1000 * 60));

  // Format hours and minutes to ensure two digits
  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');

  return `${formattedHours}h ${formattedMinutes}m`;
};

export const formatDate = (
  dateString: string,
  options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric'
  }
): string => {
  return new Date(dateString).toLocaleString(new Intl.Locale('en-US'), options);
};

export const doubleDigitHourFormat: Intl.DateTimeFormatOptions = {
  hour: '2-digit',
  hour12: false
};

export const timeFormat: Intl.DateTimeFormatOptions = {
  hour: 'numeric',
  minute: 'numeric',
  hour12: false
};

export const dateTimeFormat: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  hour12: false
};

export const dateFormat: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric'
};

export const monthDayHourMinuteFormat: Intl.DateTimeFormatOptions = {
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  hour12: false
};

export const isRangeWithinRange = (
  range: { start: string; end: string },
  rangeToCompare: { start: string; end: string }
): boolean => {
  return Boolean(
    isWithinInterval(range.start, {
      start: rangeToCompare.start,
      end: rangeToCompare.end
    }) &&
      isWithinInterval(range.end, {
        start: rangeToCompare.start,
        end: rangeToCompare.end
      })
  );
};

export const filterDataByDateRange = <T extends { timestamp: string }>(
  data: T[],
  start: string,
  end: string
) => {
  return data.filter(({ timestamp }) =>
    isWithinInterval(timestamp, { start, end })
  );
};
