import { useMemo } from 'react';

import { getTime } from 'date-fns';
import {
  CartesianGrid,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';

import { useInsightsContext } from '@context/InsightsContext';
import { formatDate, timeFormat } from '@utils/date-utils';
import {
  calculateYAxisTicks,
  processData
} from '@utils/evse-status-graph-data-processor-utils';
import { renderCustomYAxisTick } from '@utils/evse-status-graph-renderer-utils';
import { getTicksForInterval } from '@utils/insights-utils';

import { EvseStatusEnum } from '@omnis-pulse-types';

export type DataPoint = {
  x: number;
  subgroup: number;
  group: string;
  z: number;
  status: EvseStatusEnum;
  energy?: number;
  chargerName?: string;
};

type HorizontalBarChartProps = {
  data: DataPoint[];
  ShapeComponent: React.ComponentType<{
    yAxisTicks: { label: string; y: number }[];
  }>;
  TooltipComponent: React.ComponentType;
};

export const HorizontalBarChart = ({
  data,
  ShapeComponent,
  TooltipComponent
}: HorizontalBarChartProps) => {
  const processedData = processData(data);
  const yAxisTicks = calculateYAxisTicks(processedData);
  const { range } = useInsightsContext();

  const domain = useMemo<[number, number]>(() => {
    return [getTime(range[0]), getTime(range[1])];
  }, [range]);

  return (
    <div
      data-testid="horizontal-bar-chart"
      className="w-full"
      style={{
        height:
          yAxisTicks.length > 0 ? yAxisTicks[yAxisTicks.length - 1].y + 50 : 358
      }}
    >
      <ResponsiveContainer height="100%" width="100%">
        <ScatterChart>
          <CartesianGrid
            strokeDasharray="5 5"
            stroke="#EBEBEB"
            horizontal={false}
          />
          <XAxis
            dataKey="x"
            type="number"
            scale="time"
            domain={domain}
            padding={{ left: 33, right: 33 }}
            stroke={'#EBEBEB'}
            tickFormatter={tick => formatDate(tick, timeFormat)}
            tick={{ fontSize: 12, fill: '#9F9F9F', fontFamily: 'ABBvoice' }}
            ticks={getTicksForInterval(domain)}
            tickLine={false}
          />
          <YAxis
            type="category"
            allowDuplicatedCategory={false}
            dataKey="y"
            tickLine={false}
            interval={0}
            tick={props => renderCustomYAxisTick(props, yAxisTicks)}
            stroke={'#EBEBEB'}
            padding={{ top: 16 }}
            width={92}
          />
          <Scatter
            data={processedData.toReversed()}
            shape={<ShapeComponent yAxisTicks={yAxisTicks} />}
            isAnimationActive={false}
          />
          <Tooltip
            isAnimationActive={false}
            offset={0}
            content={<TooltipComponent />}
            cursor={false}
          />
        </ScatterChart>
      </ResponsiveContainer>
    </div>
  );
};
