import { usePortalTranslation } from '@hooks/use-portal-translation';
import { useParams } from 'react-router-dom';

import useSiteLoadManagementFallbackBudget from '@api/sites/use-site-load-management-fallback-budget';
import { useSiteDevicesContext } from '@context/SiteDevicesContext';
import WarningIcon from '@icons/warning_16.svg';
import { isValidPositiveNumber } from '@utils/input-utils';

import {
  AutomaticSaveInput,
  Card,
  DataError,
  LoadingSkeleton,
  Typography,
  useAlert
} from '@destination/components';

export const FallbackBudget = () => {
  const { t, t_generic } = usePortalTranslation('siteSettings');
  const { id } = useParams();
  const { data, isLoading, isError, mutate, update, isUpdating } =
    useSiteLoadManagementFallbackBudget(id);

  const { deviceData } = useSiteDevicesContext();
  const { notifyError } = useAlert();

  const errorMessage = t('errors.failed_to_save_settings', {
    entity: t('literals.fallback_budget'),
    titleize: true
  });

  const label = t('fallback_budget.label', { titleize: true });
  const invalidHelperText = t_generic('errors.invalid_input', {
    titleize: true
  });
  const errorHelperText = t_generic('errors.failed_to_save', {
    titleize: true,
    entity: t_generic('literals.capacity')
  });

  const helperText = t('fallback_budget.helper_text', {
    titleize: true,
    value: deviceData.chargers?.length
  });

  const handleSave = async (value: string) => {
    const newFallbackBudget = {
      ...data,
      evseFallbackBudget: parseFloat(value)
    };

    try {
      await update(newFallbackBudget);
    } catch {
      mutate();
      notifyError({ header: errorMessage });
    }
  };

  const renderContent = () => {
    if (isLoading) {
      return <LoadingSkeleton height={20} />;
    }

    if (isError) {
      return <DataError onRefresh={() => mutate()} />;
    }

    if (data === undefined) {
      return null;
    }

    return (
      <div className="flex flex-col gap-4 px-3 py-4">
        <div
          data-testid="fallbackBudget-disclaimer"
          className="flex gap-1 border-2 border-orange-300 bg-[#F9731610] p-3"
        >
          <div>
            <WarningIcon />
          </div>
          <Typography
            variant="description"
            className="mb-2 flex justify-center"
          >
            {t('fallback_budget.warning_message')}
          </Typography>
        </div>
        <div className="flex items-center gap-4">
          <div className="min-w-[200px]">
            <Typography>{label}</Typography>
          </div>
          <div className="w-[200px]">
            <AutomaticSaveInput
              disabled={isUpdating}
              data-testid="fallback-budget-input"
              initialValue={data.evseFallbackBudget.toString()}
              id="fallback-budget-value"
              validator={isValidPositiveNumber}
              invalidHelperText={invalidHelperText}
              errorHelperText={errorHelperText}
              onSave={handleSave}
              suffixText={<Typography variant="smallTitle">A</Typography>}
              helperText={helperText}
            />
          </div>
        </div>
      </div>
    );
  };

  return <Card className="w-[620px]">{renderContent()}</Card>;
};
