import { useParams } from 'react-router-dom';

import useSiteChargingStrategy from '@api/sites/use-site-charging-strategy';

import { Card, DataError, LoadingSkeleton } from '@destination/components';

import { ChargingStrategy as ChargingStrategyEnum } from '@omnis-pulse-types';

import { ChargingStrategyBasePower } from './ChargingStrategyBasePower';
import { ChargingStrategySelector } from './ChargingStrategyStrategy';

export const ChargingStrategy = () => {
  const { id: siteId } = useParams();

  const { data, isLoading, isError, mutate, isUpdating, update } =
    useSiteChargingStrategy(siteId);

  const handleChange = async (
    value: ChargingStrategyEnum | number,
    key: 'strategy' | 'basePower'
  ) => {
    const payload = { [key]: value };
    await update(payload);
  };

  const renderContent = () => {
    if (isError) {
      return (
        <Card className="w-[620px]">
          <DataError onRefresh={() => mutate()} />
        </Card>
      );
    }
    if (isLoading) {
      return (
        <Card className="w-[620px]">
          <LoadingSkeleton height={20} />
        </Card>
      );
    }

    return (
      <>
        <ChargingStrategySelector
          value={data?.strategy ?? null}
          onChange={value => handleChange(value, 'strategy')}
          disabled={isUpdating}
        />
        <ChargingStrategyBasePower
          initialValue={data?.basePower ?? null}
          onChange={value => handleChange(value, 'basePower')}
        />
      </>
    );
  };

  return renderContent();
};
