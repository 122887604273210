import { usePortalTranslation } from '@hooks/use-portal-translation';

import { NavigationButton } from '@components/buttons/NavigationButton';
import { ChargerSessionTable } from '@components/charger/ChargerSessionTable';

import { DataError, Typography } from '@destination/components';

import {
  ChargerActiveSession,
  ChargerFinishedSession
} from '@omnis-pulse-types';

export interface IChargerSessions {
  title: string;
  sessions: ChargerActiveSession[] | ChargerFinishedSession[];
  isLoading: boolean;
  isError: boolean;
  mutate: () => void;
}

export const ChargerSessions = ({
  title,
  sessions,
  isLoading,
  isError,
  mutate
}: IChargerSessions) => {
  const { t } = usePortalTranslation();

  const renderContent = () => {
    if (isError) {
      return <DataError onRefresh={mutate} />;
    }

    const label = t('buttons.see_all', {
      entity: t('literals.session_other'),
      titleize: true
    });

    return (
      <>
        <ChargerSessionTable
          sessions={sessions}
          isLoading={isLoading}
          isSingleLoadingRow={true}
        />
        <div className="mt-3 flex w-full justify-end">
          <NavigationButton label={label} to={'session-history'} />
        </div>
      </>
    );
  };

  return (
    <>
      <Typography variant="sectionTitle" className="mb-5 mt-[15px]">
        {title}
      </Typography>
      {renderContent()}
    </>
  );
};
