import { useTranslation } from 'react-i18next';

import { SiteSettingsContent } from '@components/sites/site/settings/SiteSettingsContent';
import { titleize } from '@utils/i18n-utils';

import { Typography } from '@destination/components';

export const SiteSettings = () => {
  const { t } = useTranslation('generic');
  const title = titleize(
    t('labels.entity_settings', {
      entity: t('literals.site')
    })
  );

  return (
    <section>
      <Typography variant="sectionTitle" className="mb-4">
        {title}
      </Typography>
      <SiteSettingsContent />
    </section>
  );
};
