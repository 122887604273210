import DonutChart, { IDonutChart } from '@components/charts/DonutChart';

import { Card, LoadingSkeleton, Typography } from '@destination/components';

interface IStatusOverview {
  isLoading: boolean;
  title: string;
  description: string;
  donutCharts: IDonutChart[];
  dataTestId?: string;
}

export const StatusOverview = ({
  isLoading,
  title,
  description,
  donutCharts,
  dataTestId
}: IStatusOverview) => {
  return (
    <div data-testid={dataTestId} className="flex-auto">
      <Typography variant="sectionTitle" className="mb-1">
        {title}
      </Typography>
      {isLoading ? (
        <>
          <LoadingSkeleton isDarker height={18} className="mb-3 w-1/2" />
          <LoadingSkeleton
            isDarker
            height={118}
            className={`${donutCharts.length === 2 ? 'w-[193px]' : 'w-[277px]'} rounded-[10px]`}
          />
        </>
      ) : (
        <>
          <Typography variant="description" className="mb-3">
            {description}
          </Typography>
          <Card
            className={`flex w-full justify-center ${donutCharts.length > 0 ? 'gap-[25px]' : ''}`}
          >
            {donutCharts.map(donutChart => (
              <DonutChart
                {...donutChart}
                key={`donut-chart-${donutChart.title}`}
              />
            ))}
          </Card>
        </>
      )}
    </div>
  );
};
