import { USER_ORGANIZATION_USERS_BY_ID_PATH } from '@api/paths';
import { modifier } from '@utils/swr-utils';

import { UpdateOrganizationUserRolesData } from '@omnis-pulse-types';

export default function updateOrganizationUserRoles(
  userId: string,
  organizationUserRolesRequest: UpdateOrganizationUserRolesData['body']
) {
  return modifier(
    USER_ORGANIZATION_USERS_BY_ID_PATH(userId),
    'PATCH',
    organizationUserRolesRequest
  );
}
