import { SITE_BY_ID_PATH } from '@api/paths';
import { modifier } from '@utils/swr-utils';

import { UpdateSitePeakCapacity } from '@omnis-pulse-types';

export default async function updateSiteEnergyManagementPeakCapacity(
  siteId: string,
  payload: UpdateSitePeakCapacity
) {
  const url = `${SITE_BY_ID_PATH(siteId)}/energy-management/peak-capacity`;
  return await modifier(url, 'PATCH', payload);
}
