import { EvseStatusYAxisTooltip } from '@components/sites/site/insights/EvseStatusYAxisTooltip';

import {
  RenderCustomYAxisTickProps,
  YAxisTick
} from './evse-status-graph-data-processor-utils';

export const renderCustomYAxisTick = (
  { payload, x, y, width }: RenderCustomYAxisTickProps,
  yAxisTicks: YAxisTick[]
) => {
  const value = payload.value;

  const textToRender = value.includes('_SUBGROUP_')
    ? `EVSE ${value.split('SUBGROUP_')[1]}`
    : value.split('GROUP_')[1];

  const truncatedText = truncateText(textToRender ?? '', width - 10, '14');

  const fontWeight = value.includes('_SUBGROUP_') ? 'normal' : 'bold';

  return (
    <EvseStatusYAxisTooltip
      text={textToRender}
      y={yAxisTicks.find(tick => tick.label === value)?.y ?? y}
      width={width}
    >
      <text
        x={x}
        y={yAxisTicks.find(tick => tick.label === value)?.y ?? y}
        fill="#333"
        fontSize="14"
        fontWeight={fontWeight}
        textAnchor="end"
      >
        {truncatedText}
      </text>
    </EvseStatusYAxisTooltip>
  );
};

const truncateText = (text: string, maxWidth: number, fontSize: string) => {
  const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
  const textElement = document.createElementNS(
    'http://www.w3.org/2000/svg',
    'text'
  );

  textElement.setAttribute('font-size', fontSize);
  svg.appendChild(textElement);
  document.body.appendChild(svg);

  let truncatedText = text;
  textElement.textContent = truncatedText;
  let textWidth = textElement.getComputedTextLength();

  while (textWidth > maxWidth && truncatedText.length > 0) {
    truncatedText = truncatedText.slice(0, -1);
    textElement.textContent = `${truncatedText}...`;
    textWidth = textElement.getComputedTextLength();
  }

  document.body.removeChild(svg);
  return text === truncatedText ? text : `${truncatedText}...`;
};
