import { usePortalTranslation } from '@hooks/use-portal-translation';
import { useParams } from 'react-router-dom';

import useSiteEvsesStatusTelemetry from '@api/sites/use-site-evses-status-telemetry';
import { HorizontalBarChart } from '@components/charts/HorizontalBarChart';
import { useInsightsContext } from '@context/InsightsContext';
import { useSiteDevicesContext } from '@context/SiteDevicesContext';

import { Card, LoadingSkeleton, Typography } from '@destination/components';

import {
  EvseStatusEnum,
  SiteChargersStatusTelemetry
} from '@omnis-pulse-types';

import { EvseStatusChartLegend } from './EvseStatusChartLegend';
import { EvseStatusChartShape } from './EvseStatusChartShape';
import { EvseStatusChartTooltip } from './EvseStatusChartTooltip';

export const EvseStatusOverview = () => {
  const { t } = usePortalTranslation();
  const { id } = useParams();

  const { range } = useInsightsContext();

  const { data, isLoading } = useSiteEvsesStatusTelemetry(
    id,
    range[0].toISOString(),
    range[1].toISOString()
  );

  const { deviceData } = useSiteDevicesContext();

  return (
    <div>
      <div className="flex">
        <Typography className="mb-4" variant="smallTitle">
          {t('labels.entity_events_overview', {
            entity: t('literals.evse').toUpperCase()
          })}
        </Typography>
      </div>
      <Card>
        <div className="h-max-[551px] flex flex-col">
          <div className="relative flex-auto" data-testid="evse-status-chart">
            {isLoading && (
              <div className="absolute inset-0 z-50 mb-[33px] ml-[94px] mr-1 mt-1 bg-white">
                <LoadingSkeleton
                  className="!h-full rounded-[10px]"
                  height={0}
                />
              </div>
            )}
            <HorizontalBarChart
              data={transformData(data ?? []).map(dataPoint => ({
                x: dataPoint.startTime,
                group: dataPoint.chargerId,
                subgroup: dataPoint.evseId,
                z: dataPoint.endTime,
                status: dataPoint.status,
                energy: dataPoint.energyConsumed,
                chargerName: deviceData.chargers.find(
                  charger => charger.id === dataPoint.chargerId
                )?.name
              }))}
              ShapeComponent={EvseStatusChartShape}
              TooltipComponent={EvseStatusChartTooltip}
            />
            <div className="flex-initial">
              <EvseStatusChartLegend />
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

interface TransformedData {
  startTime: number;
  chargerId: string;
  evseId: number;
  endTime: number;
  status: EvseStatusEnum;
  energyConsumed?: number;
}

export function transformData(
  data: SiteChargersStatusTelemetry[]
): TransformedData[] {
  const result: TransformedData[] = [];

  data.forEach(charger => {
    if (charger.evses) {
      charger.evses.forEach(evse => {
        evse.statuses.forEach(status => {
          result.push({
            startTime: new Date(status.startTime).getTime(),
            chargerId: charger.id,
            evseId: evse.id,
            endTime: status.endTime
              ? new Date(status.endTime).getTime()
              : Date.now(),
            status: status.status,
            energyConsumed: status.energyConsumed
          });
        });
      });
    }
  });

  return result;
}
