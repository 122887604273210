import { useState } from 'react';

import {
  ExpandableSettingTile,
  ExpandableSettingTileProps
} from './ExpandableSettingTile';

export type TileData = Omit<
  ExpandableSettingTileProps,
  'isExpanded' | 'onExpand' | 'onCollapse'
>;

export const SettingTiles = ({ tileData }: { tileData: TileData[] }) => {
  const [expandedTileTitle, setExpandedTileTitle] = useState<string | null>(
    null
  );

  return (
    <div className="flex min-w-[620px] flex-col gap-3">
      {tileData.map(tile => (
        <ExpandableSettingTile
          {...tile}
          title={tile.title}
          isExpanded={expandedTileTitle === tile.title}
          onExpand={() => setExpandedTileTitle(tile.title)}
          onCollapse={() => setExpandedTileTitle(null)}
          key={tile.title}
        />
      ))}
    </div>
  );
};
