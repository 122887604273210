import { usePortalTranslation } from '@hooks/use-portal-translation';

import { AutomaticSaveSwitch, Typography } from '@destination/components';

import { UpdateSitePeakCapacity } from '@omnis-pulse-types';

export interface IPeakCapacitySwitch {
  isEnabled: boolean;
  onSave: (payload: UpdateSitePeakCapacity) => Promise<void>;
}

export const PeakCapacitySwitch = ({
  isEnabled,
  onSave
}: IPeakCapacitySwitch) => {
  const { t } = usePortalTranslation();

  const handleSave = async (isEnabled: boolean) => {
    await onSave({ isEnabled });
  };

  const activeLabel = t('literals.on', { titleize: true });
  const inactiveLabel = t('literals.off', { titleize: true });
  const errorToastMessage = t('errors.failed_to_save_settings', {
    entity: t('literals.peak_capacity')
  });

  return (
    <>
      <div className="min-w-[200px]">
        <Typography>
          {t('literals.peak_capacity', { titleize: true })}
        </Typography>
      </div>
      <AutomaticSaveSwitch
        initialCheckedValue={isEnabled}
        activeLabel={activeLabel}
        inactiveLabel={inactiveLabel}
        errorToastMessage={errorToastMessage}
        onSave={handleSave}
      />
    </>
  );
};
