import { useLayoutEffect, useMemo, useState } from 'react';

import {
  TOverloadedFunction,
  usePortalTranslation
} from '@hooks/use-portal-translation';
import { getTime, subHours } from 'date-fns';
import { useTranslation } from 'react-i18next';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';

import useSiteTelemetryCurrentDelivered from '@api/sites/use-site-telemetry-current-delivered';
import { CustomCursor } from '@components/charts/CustomCursor';
import { CustomTooltip } from '@components/charts/CustomTooltip';
import { CustomYAxisTick } from '@components/charts/CustomYAxisTick';
import {
  useYAxisTickContext,
  YAxisTickProvider
} from '@context/YAxisTickContext';
import ChartIcon from '@icons/chart_96.svg';
import { generateXAxisTicks } from '@utils/chart-utils';
import { formatDate, timeFormat } from '@utils/date-utils';

import {
  DataError,
  LoadingSkeleton,
  Typography
} from '@destination/components';

import { CurrentDeliveredData } from '../insights/CurrentDeliveredGraph';
import { SiteOverviewCurrentChartLegend } from './SiteOverviewCurrentChartLegend';

const to = new Date().toISOString();
const from = subHours(to, 24).toISOString();
const domain = [getTime(from), getTime(to)];

interface ISiteOverviewCurrentChart {
  siteId: string;
}

export const SiteOverviewCurrentChart = ({
  siteId
}: ISiteOverviewCurrentChart) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'sites.site.site_overview.site_overview'
  });

  const { data, isLoading, isError, mutate } = useSiteTelemetryCurrentDelivered(
    siteId,
    from,
    to
  );

  const processedData = useMemo(() => {
    return (data || []).map(item => ({
      ...item,
      timestamp: getTime(item.timestamp)
    }));
  }, [data]);

  if (isError) {
    return (
      <div className="flex h-full w-full items-center">
        <DataError onRefresh={() => mutate(undefined, true)} />
      </div>
    );
  }

  if (isLoading) {
    return (
      <LoadingSkeleton isDarker className="!h-full rounded-[10px]" height={0} />
    );
  }

  if (processedData.length === 0) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        <ChartIcon />
        <Typography variant="smallText" className="w-48 pt-6 text-center">
          {t('no_measurements')}
        </Typography>
      </div>
    );
  }

  return (
    <YAxisTickProvider>
      <Chart data={processedData} />
    </YAxisTickProvider>
  );
};

export type DataKey =
  | 'chargersConsumption.l1'
  | 'residualLoad.l1'
  | 'currentSum.l1'
  | 'chargersConsumption.l2'
  | 'residualLoad.l2'
  | 'currentSum.l2'
  | 'chargersConsumption.l3'
  | 'residualLoad.l3'
  | 'currentSum.l3'
  | 'gridLimit';
export type VisibilityState = Record<DataKey, boolean>;

interface IChart {
  data: CurrentDeliveredData;
}

const Chart = ({ data }: IChart) => {
  const { t } = usePortalTranslation();

  const [visibleLines, setVisibleLines] = useState<VisibilityState>({
    'chargersConsumption.l1': true,
    'residualLoad.l1': true,
    'currentSum.l1': true,
    'chargersConsumption.l2': true,
    'residualLoad.l2': true,
    'currentSum.l2': true,
    'chargersConsumption.l3': true,
    'residualLoad.l3': true,
    'currentSum.l3': true,
    gridLimit: true
  });

  const { clearCollection } = useYAxisTickContext();

  useLayoutEffect(() => {
    // Clear the collection every time the data changes so that the Y-axis min/max values are recalculated
    clearCollection();
  }, [data, clearCollection]);

  return (
    <div
      className="flex h-full flex-col"
      data-testid="site-overview-current-chart"
    >
      <div className="relative flex-auto">
        <ResponsiveContainer height="100%" width="100%">
          <LineChart data={data}>
            <CartesianGrid strokeDasharray="4 4" stroke="#EBEBEB" />
            {getAxis()}
            <Tooltip
              allowEscapeViewBox={{ x: true, y: true }}
              offset={0}
              isAnimationActive={false}
              content={<CustomTooltip />}
              cursor={data.length ? <CustomCursor /> : <></>}
            />
            {lines(data, t).filter(
              line => visibleLines[line.props.dataKey as DataKey]
            )}
          </LineChart>
        </ResponsiveContainer>
      </div>
      <div className="flex-initial">
        <SiteOverviewCurrentChartLegend
          visibleLines={visibleLines}
          setVisibleLines={setVisibleLines}
        />
      </div>
    </div>
  );
};

const getAxis = () => [
  <XAxis
    key={1}
    dataKey="timestamp"
    type="number"
    scale="time"
    domain={domain}
    stroke="#EBEBEB"
    interval="preserveStartEnd"
    ticks={generateXAxisTicks()}
    tick={{ fontSize: 8, fill: '#9F9F9F', fontFamily: 'ABBvoice' }}
    tickFormatter={tick => formatDate(tick, timeFormat)}
    tickLine={false}
  />,
  <YAxis
    key={2}
    unit=" A"
    tickLine={false}
    tickFormatter={value => `${value} A`}
    tick={<CustomYAxisTick fontSize="8" />}
    stroke="#ebebeb"
    type="number"
    width={45}
  />
];

// prettier-ignore
const lines = (data: CurrentDeliveredData, t_generic: TOverloadedFunction) => [
  { dataKey: "chargersConsumption.l1", name: `${t_generic('labels.entity_consumption', { titleize: true, entity: t_generic("literals.charger_other") })} - p1`, stroke: "#000000" },
  { dataKey: "residualLoad.l1", name: `${t_generic('labels.entity_load', { entity: t_generic('literals.residual'), titleize: true })} - p1`, stroke: "#686868" },
  { dataKey: "currentSum.l1", name: `${t_generic('literals.sum', { titleize: true })} - p1`, stroke: "#D6C918" },
  { dataKey: "chargersConsumption.l2", name: `${t_generic('labels.entity_consumption', { titleize: true, entity: t_generic("literals.charger_other") })} - p2`, stroke: "#77A3FC" },
  { dataKey: "residualLoad.l2", name: `${t_generic('labels.entity_load', { entity: t_generic('literals.residual'), titleize: true })} - p2`, stroke: "#7ED6C6" },
  { dataKey: "currentSum.l2", name: `${t_generic('literals.sum', { titleize: true })} - p2`, stroke: "#2A35FF" },
  { dataKey: "chargersConsumption.l3", name: `${t_generic('labels.entity_consumption', { titleize: true, entity: t_generic("literals.charger_other") })} - p3`, stroke: "#FF9F8C" },
  { dataKey: "residualLoad.l3", name: `${t_generic('labels.entity_load', { entity: t_generic('literals.residual'), titleize: true })} - p3`, stroke: "#E64997" },
  { dataKey: "currentSum.l3", name: `${t_generic('literals.sum', { titleize: true })} - p3`, stroke: "#C27121" },
  { dataKey: "gridLimit", name: t_generic('labels.entity_limit', { titleize: true, entity: t_generic('literals.grid') }), stroke: "#00ACEC" }
].map((line, index) => (
  <Line
    data={data}
    key={index + 1}
    {...line}
    unit="A"
    type="stepAfter"
    isAnimationActive={false}
    dot={false}
    activeDot={false}
    connectNulls={true}
  />
));
