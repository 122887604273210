import { usePortalTranslation } from '@hooks/use-portal-translation';

import { DeviceStatus } from '@components/devices/DeviceStatus';
import { dateTimeFormat, formatDate } from '@utils/date-utils';
import {
  getConnectionStatusIcon,
  getHealthStatusIcon
} from '@utils/grid-meter-utils';

import { Typography } from '@destination/components';

import { GridMeter } from '@omnis-pulse-types';

export interface IGridMeterStatus {
  gridMeter: GridMeter;
}

export const GridMeterStatus = ({ gridMeter }: IGridMeterStatus) => {
  const { t } = usePortalTranslation();

  const healthStatus = 'states.' + gridMeter.healthStatus.status.toLowerCase();

  const { status, timestamp } = gridMeter.connectionStatus;
  const connectionStatus = 'states.' + status.toLowerCase();
  const formattedTimestamp = timestamp && formatDate(timestamp, dateTimeFormat);

  const healthStatusTooltip = (
    <Typography className="max-w-52" variant="smallText">
      {gridMeter.healthStatus.error}
    </Typography>
  );

  return (
    <div className="flex gap-2">
      <DeviceStatus
        data-testid="grid-meter-health-status"
        className="border-r-[1px] border-[#EBEBEB]"
        label={t('labels.entity_status', {
          entity: t('literals.health'),
          titleize: true
        })}
        value={t(healthStatus)}
        icon={getHealthStatusIcon(gridMeter.healthStatus)}
        tooltip={gridMeter.healthStatus.error && healthStatusTooltip}
      />
      <DeviceStatus
        data-testid="grid-meter-connection-status"
        label={t('labels.entity_status', {
          entity: t('literals.connection'),
          titleize: true
        })}
        value={t(connectionStatus)}
        icon={getConnectionStatusIcon(status)}
        tooltip={
          formattedTimestamp &&
          `${t('literals.last_update')} ${formattedTimestamp}`
        }
      />
    </div>
  );
};
