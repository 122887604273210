import { ComponentProps } from 'react';

import { usePortalTranslation } from '@hooks/use-portal-translation';
import { useParams } from 'react-router-dom';

import useSiteLoadManagementLimits from '@api/sites/use-site-load-management-limits';
import WarningIcon from '@icons/warning_16.svg';

import {
  Card,
  DataError,
  LoadingSkeleton,
  RadioGroup,
  Typography
} from '@destination/components';

import { ActiveLoadManagementMode } from '@omnis-pulse-types';

import { DynamicLoadManagement } from './loadManagementMode/dynamic/DynamicLoadManagement';
import { StaticLoadManagement } from './loadManagementMode/static/StaticLoadManagement';
import { PeakCapacity } from './peakCapacity/PeakCapacity';

type Options = ComponentProps<typeof RadioGroup>['options'];

const loadManagementModes = [
  ActiveLoadManagementMode.STATIC,
  ActiveLoadManagementMode.DYNAMIC
];

export const Limits = () => {
  const { id: siteId } = useParams();
  const { t, t_generic } = usePortalTranslation('siteSettings');

  const { data, isLoading, isError, mutate, isUpdating, update } =
    useSiteLoadManagementLimits(siteId);

  const options: Options = loadManagementModes.map(mode => ({
    label: t('mode.' + mode.toLowerCase(), { titleize: true }),
    description:
      mode === ActiveLoadManagementMode.STATIC ? (
        <StaticLoadManagement />
      ) : (
        <DynamicLoadManagement />
      ),
    value: mode
  }));

  const handleChange = async (value: string | number) => {
    const payload = {
      activeMode: value as ActiveLoadManagementMode
    };

    await update(payload);
  };

  const disclaimerMessage = () => {
    return (
      <div
        data-testid="fallbackBudget-disclaimer"
        className="mb-3 flex gap-1 border-2 border-orange-300 bg-[#F9731610] p-3"
      >
        <div>
          <WarningIcon />
        </div>
        <Typography variant="description" className="mb-2 flex justify-center">
          {t('limits.warning_message')}
        </Typography>
      </div>
    );
  };

  const renderContent = () => {
    if (isError) {
      return (
        <DataError
          onRefresh={() => {
            mutate();
          }}
        />
      );
    }
    if (isLoading) {
      return <LoadingSkeleton height={20} />;
    }

    const isValidMode = options.some(
      option => option.value === data?.activeMode
    );

    return (
      <div>
        {!isValidMode && disclaimerMessage()}

        <RadioGroup
          value={data?.activeMode ?? null}
          options={options}
          disabled={isUpdating}
          onChange={handleChange}
        />
      </div>
    );
  };

  return (
    <div className="flex flex-col gap-1">
      <Card className="w-[620px]">
        <div className="ml-3">
          <Typography variant="smallTitle" className="mb-4">
            {t_generic('literals.limits', { titleize: true })}
          </Typography>
          {renderContent()}
        </div>
      </Card>
      <PeakCapacity />
    </div>
  );
};
