import { randPastDate, randSemver, randUuid } from '@ngneat/falso';

import { enumValueFaker, makeArray } from '@utils/faker-utils';

import { ConnectionStatusEnum, Gateway } from '@omnis-pulse-types';

export class GatewayMockFactory {
  static createGateway(data?: Partial<Gateway>): Gateway {
    return {
      id: randUuid(),
      connectionStatus: [
        {
          type: 'ABB',
          timestamp: randPastDate().toISOString(),
          status: enumValueFaker(ConnectionStatusEnum)
        },
        {
          type: 'CPO',
          timestamp: randPastDate().toISOString(),
          status: enumValueFaker(ConnectionStatusEnum)
        }
      ],
      firmwareVersion: randSemver(),
      serialNumber: randUuid(),
      ...data
    };
  }

  static createGateways(length: number, data?: Partial<Gateway>) {
    return makeArray(length, () => this.createGateway(data));
  }
}
