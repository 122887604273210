import { usePortalTranslation } from '@hooks/use-portal-translation';

import useChargerActiveSessions from '@api/sites/chargers/use-charger-active-sessions';
import useChargerFinishedSessions from '@api/sites/chargers/use-charger-finished-sessions';

import { ChargerSessions } from './ChargerSessions';

export interface IChargerSessionsContainer {
  siteId?: string;
  chargerId: string;
  isChargerConnected: boolean;
}

export const ChargerSessionsContainer = ({
  siteId,
  chargerId,
  isChargerConnected
}: IChargerSessionsContainer) => {
  const { t } = usePortalTranslation('chargers');
  const title = t('literals.ongoing_sessions', {
    titleize: true
  });

  const { sessions, isLoading, isError, mutate } = useChargerActiveSessions(
    isChargerConnected ? siteId : undefined,
    isChargerConnected ? chargerId : undefined
  );

  // There are no active sessions or the charger is not connected
  if (!isChargerConnected || !sessions?.length) {
    return <LastFinishedChargerSession siteId={siteId} chargerId={chargerId} />;
  }

  return (
    <ChargerSessions
      title={title}
      sessions={sessions}
      isLoading={isLoading}
      isError={isError}
      mutate={mutate}
    />
  );
};

const LastFinishedChargerSession = ({
  siteId,
  chargerId
}: Omit<IChargerSessionsContainer, 'isChargerConnected'>) => {
  const { t } = usePortalTranslation('chargers');
  const title = t('literals.last_session', {
    titleize: true
  });

  const {
    sessions = [],
    isError,
    isLoading,
    mutate
  } = useChargerFinishedSessions(siteId, chargerId, '1');

  return (
    <ChargerSessions
      title={title}
      sessions={sessions}
      isLoading={isLoading}
      isError={isError}
      mutate={mutate}
    />
  );
};
