import { useOutletContext, useParams } from 'react-router-dom';

import { ChargerEventLog } from '@components/chargers/ChargerEventLogs';
import { ChargerInformation } from '@components/chargers/ChargerInformation';
import { ChargerSessionsContainer } from '@components/chargers/ChargerSessionsContainer';
import { getIsChargerConnected } from '@utils/charger-utils';

import { ChargerDetails } from '@omnis-pulse-types';

export const Charger = () => {
  const { id: siteId } = useParams();

  const charger = useOutletContext<ChargerDetails>();

  return (
    <>
      <ChargerSessionsContainer
        siteId={siteId}
        chargerId={charger.id}
        isChargerConnected={getIsChargerConnected(charger)}
      />
      <ChargerInformation charger={charger} />
      <ChargerEventLog chargerId={charger.id} />
    </>
  );
};
