import { useParams } from 'react-router-dom';

import useSiteLoadManagementLimits from '@api/sites/use-site-load-management-limits';

import { DataError, LoadingSkeleton } from '@destination/components';

import { ActiveLoadManagementMode } from '@omnis-pulse-types';

import { GridConnectionLimit } from '../GridConnectionLimit';
import { ChargingBudgetLimit } from './ChargingBudgetLimit';
import { SiteAuxiliaryCurrent } from './SiteAuxiliaryCurrent';

export const StaticLoadManagement = () => {
  const { id: siteId } = useParams();
  const { data, isLoading, isError, mutate, isUpdating, update } =
    useSiteLoadManagementLimits(siteId);

  if (!data) return null;

  const isDisabled =
    isUpdating || data.activeMode === ActiveLoadManagementMode.DYNAMIC;

  const renderContent = () => {
    if (isError) {
      return (
        <DataError
          onRefresh={() => {
            mutate();
          }}
        />
      );
    }
    if (isLoading) {
      return <LoadingSkeleton height={20} />;
    }

    return (
      <div className="mt-2">
        <div className="flex min-h-[56px] rounded-s bg-[#FAFAFA] px-3 py-2">
          <GridConnectionLimit
            value={data.staticGridConnectionLimit}
            activeMode={ActiveLoadManagementMode.STATIC}
            isDisabled={isDisabled}
            onSave={update}
          />
        </div>
        <div className="flex min-h-[56px] rounded-s px-3 py-2">
          <SiteAuxiliaryCurrent
            value={data.siteAuxiliaryCurrent}
            maxValue={data.staticGridConnectionLimit}
            isDisabled={isDisabled}
            onSave={update}
          />
        </div>
        <div className="flex min-h-[56px] rounded-s bg-[#FAFAFA] px-3 py-2">
          <ChargingBudgetLimit
            value={data.chargingBudgetLimit}
            isDisabled={isDisabled}
          />
        </div>
      </div>
    );
  };

  return renderContent();
};
