import { useState } from 'react';

import { usePortalTranslation } from '@hooks/use-portal-translation';

import EditIcon from '@icons/edit_16.svg';

import {
  Grid,
  HighlightedText,
  IconButton,
  TableLoading
} from '@destination/components';

import { UserDetails } from '@omnis-pulse-types';

import { EditUserRolesDialog } from './EditUserRolesDialog';

export interface IUsersTable {
  users: UserDetails[];
  isLoading: boolean;
  searchValue?: string;
  activeSort: string;
  sortDirection?: 'ascending' | 'descending';
  onSort: (column: string) => void;
  onUpdate: () => Promise<void>;
}

export const UsersTable = ({
  users,
  isLoading,
  searchValue,
  activeSort,
  sortDirection,
  onSort,
  onUpdate
}: IUsersTable) => {
  const [selectedUser, setSelectedUser] = useState<UserDetails>();

  const handleEdit = (userId: string) => {
    const user = users?.find(user => user.id === userId);
    if (user) {
      setSelectedUser(user);
    }
  };

  return (
    <>
      <Grid
        data-testid="users-table"
        className="grid-cols-[auto_auto_auto_minmax(150px,1fr)_72px]"
      >
        <Header
          onSort={onSort}
          activeSort={activeSort}
          sortDirection={sortDirection}
        />
        {isLoading === false && (
          <Content
            users={users}
            searchValue={searchValue}
            onEdit={handleEdit}
          />
        )}
      </Grid>
      {isLoading && <TableLoading className="mt-3" />}
      <EditUserRolesDialog
        handleCloseDialog={() => setSelectedUser(undefined)}
        user={selectedUser}
        onUpdate={onUpdate}
      />
    </>
  );
};

interface IHeader {
  onSort: (column: string) => void;
  activeSort: string;
  sortDirection?: 'ascending' | 'descending';
}

const Header = ({ onSort, activeSort, sortDirection }: IHeader) => {
  const { t } = usePortalTranslation();

  return (
    <>
      <Grid.HeadCell
        sortActive={activeSort === 'name'}
        sortDirection={sortDirection}
        onSort={() => onSort('name')}
      >
        {`${t('literals.name')} ${t('literals.surname')}`}
      </Grid.HeadCell>
      <Grid.HeadCell
        sortActive={activeSort === 'email'}
        sortDirection={sortDirection}
        onSort={() => onSort('email')}
      >
        {t('literals.email')}
      </Grid.HeadCell>
      <Grid.HeadCell>{t('literals.status')}</Grid.HeadCell>
      <Grid.HeadCell>{t('literals.role')}</Grid.HeadCell>
      <Grid.HeadCell>{''}</Grid.HeadCell>
    </>
  );
};

interface IContent {
  users: UserDetails[];
  searchValue?: string;
  onEdit: (userId: UserDetails['id']) => void;
}

const Content = ({ users, searchValue = '', onEdit }: IContent) => {
  const { t, t_generic } = usePortalTranslation('accessManagement');

  return (
    <>
      {users.map((user, index) => (
        <Grid.TableRow
          key={user.id}
          data-testid={`row-user-${user.id}`}
          alternateRowColor={index % 2 !== 0}
        >
          <Grid.DataCell className="content-center">
            <HighlightedText
              text={user.displayName ?? ''}
              highlighted={searchValue}
            />
          </Grid.DataCell>
          <Grid.DataCell className="content-center sm:break-all lg:break-normal">
            <HighlightedText
              text={(user.email ?? '').replace('@', '@\u200B')}
              highlighted={searchValue.replace('@', '@\u200B')}
            />
          </Grid.DataCell>
          <Grid.DataCell className="content-center">
            {t_generic(`states.${user.state.toLowerCase()}`, {
              titleize: true
            })}
          </Grid.DataCell>
          <Grid.DataCell className="content-center">
            {user.applicationRoles
              .map(role => t(`roles.${role.toLowerCase()}`, { titleize: true }))
              .join(', ')}
          </Grid.DataCell>
          <Grid.DataCell className="relative">
            <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform">
              <IconButton
                data-testid={`edit-user-${user.id}-btn`}
                onClick={() => onEdit(user.id)}
              >
                <EditIcon />
              </IconButton>
            </div>
          </Grid.DataCell>
        </Grid.TableRow>
      ))}
    </>
  );
};
