import { usePortalTranslation } from '@hooks/use-portal-translation';

import { isValidPositiveNumber } from '@utils/input-utils';
import { ConvertKilowattToWatt, ConvertWattToKilowatt } from '@utils/utils';

import { AutomaticSaveInput, Card, Typography } from '@destination/components';

interface BasePowerProps {
  initialValue: number | null;
  onChange: (value: number) => Promise<void>;
}

export const ChargingStrategyBasePower = ({
  initialValue,
  onChange
}: BasePowerProps) => {
  const { t_generic } = usePortalTranslation('siteSettings');

  const label = t_generic('literals.base_power', { titleize: true });
  const invalidHelperText = t_generic('errors.invalid_input', {
    titleize: true
  });
  const errorHelperText = t_generic('errors.failed_to_save', {
    titleize: true,
    entity: t_generic('literals.base_power')
  });

  const handleSave = async (value: string) => {
    if (isValidPositiveNumber(value)) {
      const parsedValue = ConvertKilowattToWatt(parseFloat(value));
      await onChange(parsedValue);
    }
  };

  return (
    <Card className="mb-2 flex w-[620px] flex-col">
      <div className="flex items-center gap-4">
        <div className="min-w-[200px]">
          <Typography>{label}</Typography>
        </div>
        <div className="w-[200px]">
          <AutomaticSaveInput
            data-testid="base-power-input"
            initialValue={
              initialValue != null
                ? ConvertWattToKilowatt(initialValue).toString()
                : ''
            }
            id="base-power-value"
            validator={value => isValidPositiveNumber(value)}
            invalidHelperText={invalidHelperText}
            errorHelperText={errorHelperText}
            onSave={handleSave}
            suffixText={<Typography variant="smallTitle">kW</Typography>}
          />
        </div>
      </div>
    </Card>
  );
};
