import { ReactNode } from 'react';

import InfoIcon from '@icons/info_16.svg';

import { Card, Typography } from '@destination/components';

export interface ExpandableSettingTileProps {
  title: string;
  'data-testid'?: string;
  expandableContent: ReactNode;
  isExpanded: boolean;
  onExpand: () => void;
  onCollapse: () => void;
}

export const ExpandableSettingTile = ({
  title,
  expandableContent,
  isExpanded,
  onExpand,
  'data-testid': dataTestId,
  onCollapse
}: ExpandableSettingTileProps) => {
  return (
    <div
      className="[&>div>div>div]:items-start"
      data-testid={`tile-${dataTestId}`}
    >
      <Card
        isExpanded={isExpanded}
        expandableCardContent={expandableContent}
        onExpand={onExpand}
        onCollapse={onCollapse}
      >
        <div className="flex items-center">
          <div className="flex h-6 w-6 items-center justify-center">
            <InfoIcon data-testid="info-icon" className="text-[#BABABA]" />
          </div>
          <Typography
            className="pl-1"
            variant="sectionTitle"
            data-testid={`tile-${dataTestId}-header`}
          >
            {title}
          </Typography>
        </div>
      </Card>
    </div>
  );
};
