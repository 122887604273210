import { usePermission } from '@hooks/use-permission';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { mutate } from 'swr';

import { SITE_PATH } from '@api/paths';
import useChargers from '@api/sites/chargers/use-chargers';
import deleteSite from '@api/sites/delete-site';
import { Action, ResourceType } from '@constants/Permissions';

import {
  BulletMenu,
  Tooltip,
  Typography,
  useAlert,
  useDialog
} from '@destination/components';

import { Site } from '@omnis-pulse-types';

interface IDeleteSiteMenuItem {
  site: Site;
}

export const DeleteSiteMenuItem = ({ site }: IDeleteSiteMenuItem) => {
  const { id = '' } = useParams();
  const navigate = useNavigate();

  const { trigger } = useDialog();
  const { notify, notifyError } = useAlert();

  const isAllowed = usePermission();

  const { chargers } = useChargers(id);

  const { t } = useTranslation(undefined, {
    keyPrefix: 'sites.site_header'
  });

  const handleClickDeleteMenuItem = () => {
    const _notifyError = () => {
      notifyError({
        header: t('messages.command_send_failed', {
          site: site.label
        }),
        'data-testid': 'site-delete-failed-alert'
      });
    };

    const _notifySuccess = () => {
      notify({
        variant: 'success',
        header: t('messages.command_send_successfully', {
          site: site.label
        }),
        isDismissible: true,
        'data-testid': 'site-delete-successful-alert'
      });
    };

    const handleConfirm = async () => {
      const { error } = await deleteSite(site.id);

      if (error) {
        _notifyError();
      } else {
        _notifySuccess();
        await mutate(
          key => typeof key === 'string' && key.startsWith(`${SITE_PATH}?`),
          undefined,
          { revalidate: true }
        );
        navigate('..', { relative: 'path' });
      }
    };

    trigger({
      header: t('delete_site_dialog.header', {
        name: site ? site.label : 'Site'
      }),
      content: t('delete_site_dialog.content'),
      confirmButton: t('delete_site_dialog.button.delete_label'),
      variant: 'error',
      onConfirm: handleConfirm,
      onCancel: () => undefined,
      'data-testid': 'confirm-delete-site-dialog'
    });
  };

  const tooltipMessage = (
    <div className="max-w-[267px] break-words">
      <Typography variant="smallText" className="mb-[4px]">
        {t('delete_site_disabled_tooltip')}
      </Typography>
    </div>
  );

  return (
    <>
      {isAllowed(Action.DELETE, ResourceType.SITE, site) ? (
        <Tooltip
          message={(chargers ?? []).length > 0 ? tooltipMessage : ''}
          placement="bottom-center"
        >
          <BulletMenu.MenuItem
            onClick={handleClickDeleteMenuItem}
            disabled={(chargers ?? []).length > 0}
          >
            {t('menu_item.delete_site')}
          </BulletMenu.MenuItem>
        </Tooltip>
      ) : (
        <></>
      )}
    </>
  );
};
