import { useDateFilter } from '@hooks/use-date-filter';
import { useParams } from 'react-router-dom';

import useSiteEventLog from '@api/sites/use-site-event-log';
import { DateTimeFilterButtons } from '@components/buttons/DateTimeFilterButtons';
import { LogsContainer } from '@components/table/logs/LogsContainer';

import { Card } from '@destination/components';

export const Logs = () => {
  const { id } = useParams() as { id: string };
  const { selectedFilter, fromTimestamp, toTimestamp, handleFilterChange } =
    useDateFilter();

  const params = new URLSearchParams();

  if (fromTimestamp) params.append('from', fromTimestamp.toString());
  if (toTimestamp) params.append('to', toTimestamp.toString());

  const {
    logs,
    isLoading,
    isLoadingMore,
    isError,
    hasReachedEnd,
    handleLoadMore
  } = useSiteEventLog(id, params);

  return (
    <>
      <DateTimeFilterButtons
        selectedFilter={selectedFilter}
        onFilterChange={handleFilterChange}
      />
      <Card className="!shadow-none">
        <LogsContainer
          isError={isError}
          isLoadingInitial={isLoading}
          isLoadingMore={isLoadingMore}
          isCompleted={hasReachedEnd}
          logs={logs}
          onLoadMore={handleLoadMore}
        />
      </Card>
    </>
  );
};
