import { usePortalTranslation } from '@hooks/use-portal-translation';

import { DetailLine } from '@components/DetailsLine';
import { greaterThanZero } from '@utils/input-utils';

import { AutomaticSaveInput } from '@destination/components';

import {
  ActiveLoadManagementMode,
  UpdateSiteLoadManagementLimits
} from '@omnis-pulse-types';

export interface IGridConnectionLimit {
  value: number;
  activeMode: ActiveLoadManagementMode;
  isDisabled?: boolean;
  onSave: (payload: UpdateSiteLoadManagementLimits) => Promise<void>;
}

export const GridConnectionLimit = ({
  value,
  activeMode,
  isDisabled,
  onSave
}: IGridConnectionLimit) => {
  const { t, t_generic } = usePortalTranslation('siteSettings');

  let className = '[&_strong]:text-[14px] [&>div]:w-[189px]';
  if (isDisabled) className += ' text-[#BABABA]';

  const handleSave = async (value: string) => {
    const payload: UpdateSiteLoadManagementLimits =
      activeMode === ActiveLoadManagementMode.STATIC
        ? { staticGridConnectionLimit: Number(value) }
        : { dynamicGridConnectionLimit: Number(value) };

    await onSave(payload);
  };

  const validateInput = (value: string) =>
    greaterThanZero(value) && /^\d+(\.\d)?$/.test(value);

  const label = t('literals.grid_connection_limit', { titleize: true });
  const helperText = t_generic('errors.invalid_input', { titleize: true });

  return (
    <DetailLine label={label} className={className}>
      <AutomaticSaveInput
        id="grid-connection-limit"
        data-testid="grid-connection-limit"
        initialValue={value.toString()}
        disabled={isDisabled}
        validator={validateInput}
        invalidHelperText={helperText}
        errorHelperText={t_generic('errors.failed_to_save', {
          titleize: true,
          entity: t('literals.grid_connection_limit')
        })}
        onSave={handleSave}
        suffixText={<strong data-testid="unit">A</strong>}
      />
    </DetailLine>
  );
};
