import { useState } from 'react';

import { usePortalTranslation } from '@hooks/use-portal-translation';
import { NavLink, Outlet, useParams } from 'react-router-dom';

import { LinkGatewayToSiteForm } from '@components/gateways/LinkGatewayToSiteForm';
import { useSiteDevicesContext } from '@context/SiteDevicesContext';
import ChargerIcon from '@icons/charger_96.svg';

import { Button, Card, NothingConfigured, Tab } from '@destination/components';

export const Devices = () => {
  const { t, t_generic } = usePortalTranslation('devices');
  const { id = '' } = useParams();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const basePath = `/sites/${id}/devices`;
  const { deviceData } = useSiteDevicesContext();

  const tabs = [];

  if (deviceData.hasCharger) {
    tabs.push({ label: 'tab.chargers', path: '/chargers' });
  }

  if (deviceData.hasGridMeter) {
    tabs.push({ label: 'tab.grid_meter', path: '/grid-meters' });
  }

  if (deviceData.hasGateway) {
    tabs.push({ label: 'tab.terra_gateway_pro', path: '/gateways' });
  }

  if (deviceData.hasNoDevices) {
    return (
      <>
        <NothingConfigured
          title={t_generic('labels.no_configured', {
            entity: t_generic('literals.device_other')
          })}
          className="mt-32"
          icon={<ChargerIcon />}
        >
          <Button
            data-testid="link-gateway-button"
            variant="accent"
            className="max-w-[263px] text-center"
            onClick={() => setIsDialogOpen(true)}
          >
            {t('add_gateway_button')}
          </Button>
        </NothingConfigured>
        <LinkGatewayToSiteForm
          showDialog={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
        />
      </>
    );
  }

  return (
    <>
      <nav className="tw-inline-flex gap-4">
        {tabs.map(tab => (
          <NavLink
            key={tab.label}
            to={`${basePath}${tab.path}`}
            end={
              tab.path !== '/chargers' &&
              tab.path !== '/grid-meters' &&
              tab.path !== '/gateways'
            }
          >
            {({ isActive }) => (
              <Tab
                data-testid={`tab-${tab.label}`}
                isActive={isActive}
                isSubTab={true}
              >
                {t(tab.label)}
              </Tab>
            )}
          </NavLink>
        ))}
      </nav>
      <Card className="flex grow flex-col !rounded-tl-none !shadow-none">
        <Outlet />
      </Card>
    </>
  );
};
