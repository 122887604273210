import OfflineIcon from '@icons/offline_16.svg';
import OnlineIcon from '@icons/online_16.svg';
import ErrorIcon from '@icons/red_error_16.svg';
import WarningIcon from '@icons/warning_16.svg';

import {
  ConnectionStatusEnum,
  HealthStatus,
  HealthStatusEnum
} from '@omnis-pulse-types';

export const getConnectionStatusIcon = (status: ConnectionStatusEnum) => {
  switch (status) {
    case ConnectionStatusEnum.CONNECTED:
      return (
        <OnlineIcon
          className="text-[#0CA919]"
          data-testid="connected-status-icon"
        />
      );
    case ConnectionStatusEnum.NOT_CONNECTED:
      return (
        <OfflineIcon
          className="text-[#BABABA]"
          data-testid="not-connected-status-icon"
        />
      );
    default:
      return <WarningIcon data-testid="unknown-status-icon" />;
  }
};

export const getHealthStatusIcon = (status: HealthStatus) => {
  switch (status.status) {
    case HealthStatusEnum.HEALTHY:
      return (
        <OnlineIcon
          className="text-[#0CA919]"
          data-testid="health-status-healthy-icon"
        />
      );
    case HealthStatusEnum.ERROR:
      return <ErrorIcon data-testid="health-status-error-icon" />;
    default:
      return <WarningIcon data-testid="health-status-unknown-icon" />;
  }
};
