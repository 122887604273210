import { useCallback } from 'react';

import { usePortalTranslation } from '@hooks/use-portal-translation';
import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';

import { getToken } from '@api/api';
import { SITE_BY_ID_PATH } from '@api/paths';
import { axiosInstance, fetcher } from '@utils/swr-utils';

import { useAlert } from '@destination/components';

import type {
  SiteLoadManagementLimits,
  UpdateSiteLoadManagementLimits
} from '@omnis-pulse-types';

export type UseSiteLoadManagementLimitsReturn = {
  data: SiteLoadManagementLimits | undefined;
  isLoading: boolean;
  isError: boolean;
  isUpdating: boolean;
  update: (payload: UpdateSiteLoadManagementLimits) => Promise<void>;
  mutate: () => Promise<SiteLoadManagementLimits | undefined>;
};

export async function updateSiteLoadManagementLimitsLimits(
  url: string,
  { arg }: { arg: UpdateSiteLoadManagementLimits }
) {
  const token = await getToken();
  return axiosInstance.patch(url, arg, {
    headers: { Authorization: `Bearer ${token}` }
  });
}

export default function useSiteLoadManagementLimits(
  siteId?: string
): UseSiteLoadManagementLimitsReturn {
  const { t } = usePortalTranslation();
  const { notify, notifyError } = useAlert();

  const successMsg = t('labels.save_settings_success', {
    entity: t('literals.limits'),
    titleize: true
  });
  const errorMsg = t('errors.failed_to_save_settings', {
    entity: t('literals.limits'),
    titleize: true
  });

  const { data, isLoading, error, mutate } = useSWR<SiteLoadManagementLimits>(
    siteId ? `${SITE_BY_ID_PATH(siteId)}/load-management/limits` : null,
    fetcher
  );

  const { trigger, isMutating: isUpdating } = useSWRMutation(
    siteId ? `${SITE_BY_ID_PATH(siteId)}/load-management/limits` : null,
    updateSiteLoadManagementLimitsLimits,
    {
      rollbackOnError: true,
      revalidate: true,
      onSuccess: () => {
        notify({ variant: 'success', header: successMsg });
      },
      onError: () => {
        notifyError({ header: errorMsg });
      }
    }
  );

  const updateLimits = useCallback(
    async (payload: UpdateSiteLoadManagementLimits) => {
      await trigger(payload);
      mutate();
    },
    [trigger, mutate]
  );

  return {
    data,
    isLoading,
    isError: Boolean(error),
    isUpdating,
    update: updateLimits,
    mutate
  };
}
