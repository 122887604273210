import { usePortalTranslation } from '@hooks/use-portal-translation';

import { SettingTiles, TileData } from '@components/sites/SettingTiles';

import { ChargingStrategy } from './chargingStrategy/ChargingStrategy';
import { FallbackBudget } from './fallbackBudget/FallbackBudget';
import { Limits } from './Limits';

export const SiteSettingsContent = () => {
  const { t } = usePortalTranslation();
  const tileData: TileData[] = [
    {
      title: t('literals.limits', { titleize: true }),
      expandableContent: <Limits />,
      'data-testid': 'limit-tile'
    },
    {
      title: t('labels.entity_strategy', {
        entity: t('literals.charging'),
        titleize: true
      }),
      expandableContent: <ChargingStrategy />,
      'data-testid': 'charging-strategy-tile'
    },
    {
      title: t('literals.fallback_budget', {
        titleize: true
      }),
      expandableContent: <FallbackBudget />,
      'data-testid': 'fallback-budget-tile'
    }
  ];

  return <SettingTiles tileData={tileData} />;
};
