import { Grid, HighlightedText } from '@destination/components';

interface IChargerNameAndSerialTableCell {
  name: string;
  serial: string;
  highlighted: string;
}

export const ChargerNameAndSerialTableCell = ({
  name,
  serial,
  highlighted
}: IChargerNameAndSerialTableCell) => {
  return (
    <Grid.DataCell className="content-center break-all">
      <div className="mb-1 truncate font-bold">
        <HighlightedText text={name} highlighted={highlighted} />
      </div>
      <div>
        <HighlightedText text={serial} highlighted={highlighted} />
      </div>
    </Grid.DataCell>
  );
};
