import React, { Dispatch, SetStateAction } from 'react';

import { usePortalTranslation } from '@hooks/use-portal-translation';

import { LegendItem } from '@components/charts/LegendItem';

import { DataKey, VisibilityState } from './SiteOverviewCurrentChart';

const colors = [
  ['bg-black', 'bg-[#77A3FC]', 'bg-[#FF9F8C]'],
  ['bg-[#686868]', 'bg-[#7ED6C6]', 'bg-[#E64997]'],
  ['bg-[#D6C918]', 'bg-[#2A35FF]', 'bg-[#C27121]']
];

const phases = [1, 2, 3];

interface ICurrentDeliveredLegend {
  visibleLines: VisibilityState;
  setVisibleLines: Dispatch<SetStateAction<VisibilityState>>;
}

export const SiteOverviewCurrentChartLegend = ({
  visibleLines,
  setVisibleLines
}: ICurrentDeliveredLegend) => {
  const { t } = usePortalTranslation();

  const handleToggleLine = (key: string) => {
    setVisibleLines(prev => ({
      ...prev,
      [key]: !prev[key as DataKey]
    }));
  };

  const consumptionLabel = t('labels.entity_consumption', {
    entity: t('literals.charger_other'),
    titleize: true
  });
  const residualLabel = t('labels.entity_load', {
    entity: t('literals.residual'),
    titleize: true
  });
  const sumLabel = t('literals.sum', { titleize: true });
  const phaseLabel = t('literals.phase');

  return (
    <div className="flex flex-wrap gap-x-6 text-nowrap">
      {phases.map(phase => (
        <React.Fragment key={phase}>
          <LegendItem
            isToggled={visibleLines[`chargersConsumption.l${phase}` as DataKey]}
            color={colors[0][phase - 1]}
            label={`${consumptionLabel} - ${phaseLabel} ${phase}`}
            onClick={() => handleToggleLine(`chargersConsumption.l${phase}`)}
          />
          <LegendItem
            isToggled={visibleLines[`residualLoad.l${phase}` as DataKey]}
            color={colors[1][phase - 1]}
            label={`${residualLabel} - ${phaseLabel} ${phase}`}
            onClick={() => handleToggleLine(`residualLoad.l${phase}`)}
          />
          <LegendItem
            isToggled={visibleLines[`currentSum.l${phase}` as DataKey]}
            color={colors[2][phase - 1]}
            label={`${sumLabel} - ${phaseLabel} ${phase}`}
            onClick={() => handleToggleLine(`currentSum.l${phase}`)}
          />
        </React.Fragment>
      ))}
      <LegendItem
        isToggled={visibleLines['gridLimit']}
        color="bg-[#00ACEC]"
        label={t('labels.entity_limit', {
          entity: t('literals.grid'),
          titleize: true
        })}
        onClick={() => handleToggleLine('gridLimit')}
      />
    </div>
  );
};
