import { usePortalTranslation } from '@hooks/use-portal-translation';

import { DeviceStatus } from '@components/devices/DeviceStatus';
import { getConnectionStatusIcon } from '@utils/charger-utils';
import { dateTimeFormat, formatDate } from '@utils/date-utils';

import { Gateway } from '@omnis-pulse-types';

export interface IGatewayStatus {
  gateway: Gateway;
}

export const GatewayStatus = ({ gateway }: IGatewayStatus) => {
  const { t } = usePortalTranslation();

  const connectionStatuses = [
    { type: 'ABB', labelKey: 'labels.entity_service' },
    { type: 'CPO', labelKey: 'labels.entity_connection' }
  ];

  return (
    <div className="flex gap-2">
      {connectionStatuses.map(({ type, labelKey }) => {
        const connectionStatus = gateway.connectionStatus.find(
          status => status.type === type
        );
        if (!connectionStatus) return null;

        const label = t(labelKey, { entity: type, titleize: true });
        const statusValue = t(
          'states.' + connectionStatus.status.toLowerCase(),
          {
            titleize: true
          }
        );
        const tooltip = `${t('literals.last_update', { titleize: true })} ${formatDate(connectionStatus.timestamp, dateTimeFormat)}`;

        return (
          <DeviceStatus
            key={type}
            data-testid={`gateway-${type.toLowerCase()}-connection-status`}
            label={label}
            value={statusValue}
            icon={getConnectionStatusIcon(connectionStatus.status)}
            tooltip={tooltip}
          />
        );
      })}
    </div>
  );
};
