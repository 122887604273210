/* eslint-disable react-compiler/react-compiler */
import { useMemo, useRef, useState } from 'react';

import { getTime } from 'date-fns';

import useChargerEvseTelemetry from '@api/chargers/use-charger-evse-telemetry';
import { useInsightsContext } from '@context/InsightsContext';
import { YAxisTickProvider } from '@context/YAxisTickContext';

import { Typography } from '@destination/components';

import { PhaseCurrent } from '@omnis-pulse-types';

import { ChargerCurrentDeliveredLegend } from './ChargerCurrentDeliveredLegend';
import { EvsePhaseCurrentChart } from './EvsePhaseCurrentChart';

export type DataKey = 'l1' | 'l2' | 'l3' | 'soc';
export type VisibilityState = Record<DataKey, boolean>;

export const ChargerCurrentDelivered = ({
  chargerId
}: {
  chargerId: string;
}) => {
  const numberOfEVSERef = useRef<number>(1);

  const { range } = useInsightsContext();
  const { data, isLoading } = useChargerEvseTelemetry(
    chargerId,
    range[0].toISOString(),
    range[1].toISOString()
  );

  if (data) {
    numberOfEVSERef.current = data.length;
  }

  if (isLoading) {
    return Array.from({ length: numberOfEVSERef.current }, (_, index) => (
      <Evse
        key={index + 1}
        id={index + 1}
        data={[]}
        isLoading={true}
        isLast={numberOfEVSERef.current === index + 1}
      />
    ));
  }

  return data?.map((evse, index) => (
    <Evse
      key={evse.id}
      id={evse.id}
      data={evse.currentConsumption}
      isLoading={false}
      isLast={data.length === index + 1}
    />
  ));
};

const Evse = ({
  id,
  data,
  isLoading,
  isLast
}: {
  id: number;
  data: PhaseCurrent[];
  isLoading: boolean;
  isLast: boolean;
}) => {
  const [visibleLines, setVisibleLines] = useState<VisibilityState>({
    l1: true,
    l2: true,
    l3: true,
    soc: true
  });

  const mapped = useMemo(() => {
    return data.map(d => ({ ...d, timestamp: getTime(d.timestamp) }));
  }, [data]);

  return (
    <>
      <div className="mb-3 flex-initial">
        <Typography variant="smallTitle">{`EVSE ${id}`}</Typography>
      </div>
      <YAxisTickProvider>
        <div>
          <EvsePhaseCurrentChart
            data={mapped}
            isLoading={isLoading}
            height="239px"
            visibleLines={visibleLines}
          />
        </div>
      </YAxisTickProvider>
      <div className={`flex-initial ${isLast ? '' : 'mb-8'}`}>
        <ChargerCurrentDeliveredLegend
          visibleLines={visibleLines}
          setVisibleLines={setVisibleLines}
        />
      </div>
    </>
  );
};
