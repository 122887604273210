import { TOverloadedFunction } from '@hooks/use-portal-translation';

export const generateSiteBreadcrumbs = (
  chunks: string[],
  siteName: string | undefined,
  t: TOverloadedFunction
) => {
  const breadcrumbs: { label: string; path: string }[] = [];
  let path: string;

  const getLabel = (chunk: string, index: number): string => {
    switch (chunk) {
      case 'sites':
        return t('literals.site_other', { titleize: true });
      case 'create':
        return t('buttons.create', {
          entity: t('literals.site'),
          titleize: true
        });
      case 'devices':
        return t('literals.device_other', { titleize: true });
      case 'site-settings':
        return t('literals.site_settings', { titleize: true });
      case 'logs':
        return t('literals.log_other', { titleize: true });
      case 'insights':
        return t('literals.insights', { titleize: true });
      case 'chargers':
        return t('literals.charger_other', { titleize: true });
      case 'grid-meters':
        return t('literals.grid_meter_other', { titleize: true });
      case 'gateways':
        return t('literals.gateway_other', { titleize: true });
      case 'session-history':
        return t('labels.entity_history', {
          titleize: true,
          entity: t('literals.session')
        });
      default:
        if (index === 1 && siteName) {
          return siteName;
        }
        return chunk;
    }
  };

  chunks.forEach((chunk, index) => {
    if (chunks.length === 1) {
      return;
    }

    path = path ? `${path}/${chunk}` : chunk;
    const label = getLabel(chunk, index);

    if (index === 4) {
      breadcrumbs[3].path = path;
      return;
    }

    breadcrumbs.push({ label, path });
  });

  return breadcrumbs;
};
