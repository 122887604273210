import {
  PackedRule,
  PermissionAction,
  PermissionResourceType
} from '@omnis-pulse-types';

export const adminPermissions = ['manage', 'all'];

export const serviceManagerPermissions: PackedRule[] = [
  [PermissionAction.CREATE, PermissionResourceType.SITE],
  [
    PermissionAction.UPDATE,
    PermissionResourceType.SITE,
    {
      organizationId: '3173b321-5a4e-4b38-9e2a-6432cbf4f820'
    }
  ],
  [
    PermissionAction.READ,
    PermissionResourceType.INSTALLED_CHARGER,
    {
      site: {
        is: {
          organizationId: '3173b321-5a4e-4b38-9e2a-6432cbf4f820'
        }
      }
    }
  ],
  [
    PermissionAction.UPDATE,
    PermissionResourceType.INSTALLED_CHARGER,
    {
      site: {
        is: {
          organizationId: '3173b321-5a4e-4b38-9e2a-6432cbf4f820'
        }
      }
    }
  ],
  [
    PermissionAction.READ,
    PermissionResourceType.ORGANIZATION,
    {
      id: '3173b321-5a4e-4b38-9e2a-6432cbf4f820'
    }
  ],

  [
    PermissionAction.READ,
    PermissionResourceType.USER,
    {
      organizationId: '3173b321-5a4e-4b38-9e2a-6432cbf4f820'
    }
  ],
  [
    PermissionAction.UPDATE,
    PermissionResourceType.USER,
    {
      organizationId: '3173b321-5a4e-4b38-9e2a-6432cbf4f820'
    }
  ]
];

export const serviceEngineerPermissions: PackedRule[] = [
  [PermissionAction.CREATE, PermissionResourceType.SITE],
  [
    PermissionAction.READ,
    PermissionResourceType.SITE,
    {
      organizationId: '536F33D1-9910-46E7-A492-128E5E3FA3A4'
    }
  ],
  [
    PermissionAction.UPDATE,
    PermissionResourceType.SITE,
    {
      organizationId: '536F33D1-9910-46E7-A492-128E5E3FA3A4'
    }
  ],
  [PermissionAction.READ, PermissionResourceType.INSTALLED_CHARGER],
  [PermissionAction.CREATE, PermissionResourceType.INSTALLED_CHARGER],
  [
    PermissionAction.READ,
    PermissionResourceType.INSTALLED_CHARGER,
    {
      site: {
        is: {
          organizationId: '536F33D1-9910-46E7-A492-128E5E3FA3A4'
        }
      }
    }
  ],
  [
    PermissionAction.UPDATE,
    PermissionResourceType.INSTALLED_CHARGER,
    {
      site: {
        is: {
          organizationId: '536F33D1-9910-46E7-A492-128E5E3FA3A4'
        }
      }
    }
  ],
  [
    PermissionAction.READ,
    PermissionResourceType.CHARGER,
    {
      installedCharger: {
        is: {
          site: {
            is: {
              organizationId: '536F33D1-9910-46E7-A492-128E5E3FA3A4'
            }
          }
        }
      }
    }
  ],
  [
    PermissionAction.UPDATE,
    PermissionResourceType.CHARGER,
    {
      installedCharger: {
        is: {
          site: {
            is: {
              organizationId: '536F33D1-9910-46E7-A492-128E5E3FA3A4'
            }
          }
        }
      }
    }
  ],
  [
    PermissionAction.READ,
    PermissionResourceType.ORGANIZATION,
    {
      id: '536F33D1-9910-46E7-A492-128E5E3FA3A4'
    }
  ]
];

export const superAdminPermissions: PackedRule[] = [
  [PermissionAction.MANAGE, PermissionResourceType.ALL]
];
