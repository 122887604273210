import { useState } from 'react';

import { EvseStatusEnum } from '@omnis-pulse-types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const EvseStatusChartShape = (props: any) => {
  const { cx, cy, payload, xAxis, yAxisTicks } = props;
  const [isHovered, setIsHovered] = useState(false);

  if (payload.status === 'GroupLabel') {
    return null;
  }

  const tick = yAxisTicks.find(
    (tick: { label: string; y: number }) => tick.label === payload.y
  );
  const y =
    (tick ? tick.y - 4 : cy) -
    stateHeights[payload.status as EvseStatusEnum] / 2;

  const fill = stateColors[payload.status as EvseStatusEnum];

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const filterId = 'drop-shadow'; // Unique ID for the filter

  return (
    <svg>
      {/* Patterns */}
      <pattern
        id="diagonalHatch"
        patternUnits="userSpaceOnUse"
        width="4"
        height="4"
      >
        <path
          d="M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2"
          style={{ stroke: '#FFA200', strokeWidth: 1 }}
        />
      </pattern>
      <pattern
        id="errorPattern"
        patternUnits="userSpaceOnUse"
        width="4"
        height="4"
      >
        <path
          d="M0,0 l2,2 M2,0 l-2,2"
          style={{ stroke: '#EF3934', strokeWidth: 0.5 }}
        />
        <path
          d="M0,2 l2,-2 M2,2 l-2,-2"
          style={{ stroke: '#EF3934', strokeWidth: 0.5 }}
        />
      </pattern>

      {/* Define a filter for drop shadow */}
      <defs>
        <filter id={filterId}>
          <feDropShadow
            dx="0"
            dy="2"
            stdDeviation="2"
            floodColor="rgba(0, 0, 0, 0.2)"
          />
        </filter>
      </defs>

      {/* Rect with hover effect */}
      <rect
        data-testid="chart-rect"
        x={cx}
        y={y}
        width={xAxis.scale(payload.z) - xAxis.scale(payload.x)}
        height={stateHeights[payload.status as EvseStatusEnum]}
        fill={fill}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        filter={isHovered ? `url(#${filterId})` : 'none'} // Apply filter on hover
      />

      {/* Patterns with hover effect */}
      {payload.status === EvseStatusEnum.UNKNOWN && (
        <rect
          data-testid="diagonal-pattern"
          x={cx}
          y={y}
          width={xAxis.scale(payload.z) - xAxis.scale(payload.x)}
          height={stateHeights[payload.status as EvseStatusEnum]}
          fill="url(#diagonalHatch)"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          filter={isHovered ? `url(#${filterId})` : 'none'} // Apply filter on hover
        />
      )}
      {payload.status === EvseStatusEnum.ERROR && (
        <rect
          data-testid="error-pattern"
          x={cx}
          y={y}
          width={xAxis.scale(payload.z) - xAxis.scale(payload.x)}
          height={stateHeights[payload.status as EvseStatusEnum]}
          fill="url(#errorPattern)"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          filter={isHovered ? `url(#${filterId})` : 'none'} // Apply filter on hover
        />
      )}
    </svg>
  );
};

export const stateColors: { [key in EvseStatusEnum]: string } = {
  [EvseStatusEnum.AVAILABLE]: '#99D794',
  [EvseStatusEnum.CHARGING]: '#9FBEF7',
  [EvseStatusEnum.UNKNOWN]: '#FFD3A8',
  [EvseStatusEnum.SUSPENDED]: '#9FBEF7',
  [EvseStatusEnum.OCCUPIED]: '#9FBEF7',
  [EvseStatusEnum.ERROR]: '#FFC9CD'
};

export const stateHeights: { [key in EvseStatusEnum]: number } = {
  [EvseStatusEnum.AVAILABLE]: 4,
  [EvseStatusEnum.CHARGING]: 22,
  [EvseStatusEnum.SUSPENDED]: 22,
  [EvseStatusEnum.OCCUPIED]: 22,
  [EvseStatusEnum.UNKNOWN]: 10,
  [EvseStatusEnum.ERROR]: 16
};
