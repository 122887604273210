const BASE_PATH = '/asset-management';

export const CHARGER_PATH = `${BASE_PATH}/chargers`;
export const CHARGER_BY_ID_PATH = (id: string) => `${CHARGER_PATH}/${id}`;
export const CHARGER_ACTIONS_PATH = (id: string) =>
  `${CHARGER_BY_ID_PATH(id)}/actions`;

export const GATEWAY_BY_ID_PATH = (id: string) => `${BASE_PATH}/gateways/${id}`;
export const CHARGERS_BY_GATEWAY_BY_ID_PATH = (id: string) =>
  `${BASE_PATH}/gateways/${id}/chargers`;

export const GRID_METER_BY_ID_PATH = (id: string) =>
  `${BASE_PATH}/grid-meters/${id}`;

export const SITE_PATH = `${BASE_PATH}/sites`;
export const SITE_BY_ID_PATH = (id: string) => `${SITE_PATH}/${id}`;
export const CHARGERS_BY_SITE_ID_PATH = (id: string) =>
  `${SITE_PATH}/${id}/chargers`;
export const DEVICES_BY_SITE_ID_PATH = (id: string) =>
  `${SITE_PATH}/${id}/devices`;

export const HARDWARE_MODELS_PATH = `${BASE_PATH}/hardware-models`;

export const USER_DETAILS_PATH = `${BASE_PATH}/user-account/me`;
export const USER_NOTIFICATION_SETTINGS_PATH = `${BASE_PATH}/user-account/notification-settings`;
export const USER_NOTIFICATION_SETTINGS_BY_ID_PATH = (id: string) =>
  `${USER_NOTIFICATION_SETTINGS_PATH}/${id}`;
export const USER_ORGANIZATION_DETAILS_PATH = `${BASE_PATH}/user-account/organization`;
export const USER_ORGANIZATIONS_PATH = `${BASE_PATH}/user-account/organizations`;
export const USER_TOKEN_HINT_PATH = (organizationOmnisId: string) =>
  `${BASE_PATH}/user-account/organizations/${organizationOmnisId}/id-token-hint`;
export const USER_ORGANIZATION_USERS_PATH = `${BASE_PATH}/user-account/organization/users`;
export const USER_ORGANIZATION_USERS_BY_ID_PATH = (id: string) =>
  `${USER_ORGANIZATION_USERS_PATH}/${id}`;
