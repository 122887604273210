import { useCallback } from 'react';

import { usePortalTranslation } from '@hooks/use-portal-translation';
import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';

import { getToken } from '@api/api';
import { SITE_BY_ID_PATH } from '@api/paths';
import { axiosInstance, fetcher } from '@utils/swr-utils';

import { useAlert } from '@destination/components';

import { SiteFallbackBudget } from '@omnis-pulse-types';

export type UseSiteLoadManagementFallbackBudgetReturn = {
  data: SiteFallbackBudget | undefined;
  isLoading: boolean;
  isError: boolean;
  isUpdating: boolean;
  update: (payload: SiteFallbackBudget) => Promise<void>;
  mutate: () => Promise<SiteFallbackBudget | undefined>;
};

async function updateSiteLoadManagementFallbackBudget(
  url: string,
  { arg }: { arg: SiteFallbackBudget }
) {
  const token = await getToken();
  return axiosInstance.patch(url, arg, {
    headers: { Authorization: `Bearer ${token}` }
  });
}

export default function useSiteLoadManagementFallbackBudget(
  siteId?: string
): UseSiteLoadManagementFallbackBudgetReturn {
  const { notify, notifyError } = useAlert();
  const { t } = usePortalTranslation();

  const successMsg = t('labels.save_settings_success', {
    entity: t('literals.fallback_budget'),
    titleize: true
  });
  const errorMsg = t('errors.failed_to_save_settings', {
    entity: t('literals.fallback_budget'),
    titleize: true
  });

  const { data, error, isLoading, mutate } = useSWR<SiteFallbackBudget>(
    siteId
      ? `${SITE_BY_ID_PATH(siteId)}/load-management/fallback-budget`
      : null,
    fetcher
  );

  const { trigger, isMutating: isUpdating } = useSWRMutation(
    siteId
      ? `${SITE_BY_ID_PATH(siteId)}/load-management/fallback-budget`
      : null,
    updateSiteLoadManagementFallbackBudget,
    {
      rollbackOnError: true,
      revalidate: true,
      onSuccess: () => {
        notify({ variant: 'success', header: successMsg });
      },
      onError: () => {
        notifyError({ header: errorMsg });
      }
    }
  );

  const update = useCallback(
    async (payload: SiteFallbackBudget) => {
      await trigger(payload);
      mutate();
    },
    [trigger, mutate]
  );

  return {
    data,
    isLoading,
    isError: Boolean(error),
    isUpdating,
    update,
    mutate
  };
}
