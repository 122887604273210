import { usePortalTranslation } from '@hooks/use-portal-translation';
import useSWRMutation from 'swr/mutation';

import { getToken } from '@api/api';
import { USER_NOTIFICATION_SETTINGS_PATH } from '@api/paths';
import { axiosInstance } from '@utils/swr-utils';

import { useAlert } from '@destination/components';

import {
  UserNotificationSettings,
  UserNotificationSettingsType
} from '@omnis-pulse-types';

async function sendRequest(
  url: string,
  { arg }: { arg: UserNotificationSettingsType }
) {
  const token = await getToken();

  return (
    axiosInstance
      // This is a "hack" to type the cache value
      .put<UserNotificationSettings>(url, arg, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(res => res.data)
  );
}

export default function useUpdateUserNotificationSettings(
  currentData: UserNotificationSettingsType
) {
  const { t } = usePortalTranslation();

  const { notify, notifyError } = useAlert();

  const { trigger, isMutating } = useSWRMutation(
    USER_NOTIFICATION_SETTINGS_PATH,
    (url, { arg }: { arg: UserNotificationSettingsType }) =>
      sendRequest(url + `/${currentData.id}`, { arg }),
    {
      rollbackOnError: true,
      revalidate: false,
      onSuccess: () => {
        notify({
          variant: 'success',
          header: t('labels.save_settings_success', {
            entity: t('literals.notification'),
            titleize: true
          })
        });
      },
      onError: () => {
        notifyError({
          header: t('errors.failed_to_save_settings', {
            entity: t('literals.notification'),
            titleize: true
          })
        });
      }
    }
  );

  const update = async (payload: UserNotificationSettingsType) => {
    await trigger(payload, {
      optimisticData(current) {
        if (!current) return { types: [] };

        const optimisticData = { ...current };
        optimisticData.types = [...current.types];

        const idx = optimisticData.types.findIndex(
          type => type.id === payload.id
        );

        optimisticData.types[idx] = payload;

        return optimisticData;
      }
    });
  };

  return {
    update,
    isMutating
  };
}
