import { useMemo, useState } from 'react';

import { getTime } from 'date-fns';
import { useTranslation } from 'react-i18next';

import useChargerEvseTelemetry from '@api/chargers/use-charger-evse-telemetry';
import { VisibilityState } from '@components/sites/site/insights/ChargerCurrentDelivered';
import { ChargerCurrentDeliveredLegend } from '@components/sites/site/insights/ChargerCurrentDeliveredLegend';
import { EvsePhaseCurrentChart } from '@components/sites/site/insights/EvsePhaseCurrentChart';
import { YAxisTickProvider } from '@context/YAxisTickContext';

import { Drawer, LoadingSkeleton, Typography } from '@destination/components';

import { ChargerActiveSession, EvseStatus } from '@omnis-pulse-types';

import { EvseSessionDetails } from './EvseSessionDetails';

export interface IEvseSessionDrawer {
  isOpen: boolean;
  title: string;
  onClose: () => void;
  chargerId: string;
  evse: EvseStatus;
  session: ChargerActiveSession;
}

export const EvseSessionDrawer = ({
  isOpen,
  title,
  onClose,
  chargerId,
  evse,
  session
}: IEvseSessionDrawer) => {
  const [endTime] = useState(new Date().toISOString());
  const { t } = useTranslation(undefined, {
    keyPrefix:
      'sites.site.site_overview.chargers_overview_table.charger_session_drawer'
  });

  const { data, isLoading } = useChargerEvseTelemetry(
    chargerId,
    session.startTime,
    endTime
  );

  const phaseCurrentData = data?.find(
    telemetry => telemetry.id === evse.id
  )?.currentConsumption;

  const [visibleLines, setVisibleLines] = useState<VisibilityState>({
    l1: true,
    l2: true,
    l3: true,
    soc: true
  });

  const mapped = useMemo(() => {
    return (phaseCurrentData || []).map(d => ({
      ...d,
      timestamp: getTime(d.timestamp)
    }));
  }, [phaseCurrentData]);

  return (
    <div onClick={event => event.stopPropagation()}>
      <Drawer title={title} isOpen={isOpen} onClose={onClose}>
        <Typography variant="description">{t('session_details')}</Typography>
        <div className="mt-4">
          {isLoading && <LoadingSkeleton height={103} />}
          {phaseCurrentData && (
            <>
              <YAxisTickProvider>
                <EvsePhaseCurrentChart
                  data={mapped}
                  startTime={session.startTime}
                  endTime={endTime}
                  height={'140px'}
                  visibleLines={visibleLines}
                />
              </YAxisTickProvider>
              <div className="mb-8 flex-initial">
                <ChargerCurrentDeliveredLegend
                  visibleLines={visibleLines}
                  setVisibleLines={setVisibleLines}
                />
              </div>
            </>
          )}
          <EvseSessionDetails evse={evse} session={session} />
        </div>
      </Drawer>
    </div>
  );
};
