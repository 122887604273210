import { Dispatch, SetStateAction } from 'react';

import { usePortalTranslation } from '@hooks/use-portal-translation';

import { LegendItem } from '@components/charts/LegendItem';

import { DataKey, VisibilityState } from './ChargerCurrentDelivered';

interface IChargerCurrentDeliveredLegend {
  visibleLines: VisibilityState;
  setVisibleLines: Dispatch<SetStateAction<VisibilityState>>;
}

export const ChargerCurrentDeliveredLegend = ({
  visibleLines,
  setVisibleLines
}: IChargerCurrentDeliveredLegend) => {
  const { t_generic, t } = usePortalTranslation('chargers');

  const handleToggleLine = (key: string) => {
    setVisibleLines(prev => ({
      ...prev,
      [key]: !prev[key as DataKey]
    }));
  };

  return (
    <div className="ml-[45px] flex gap-6">
      <LegendItem
        isToggled={visibleLines['l1']}
        color="bg-black"
        label={`${t_generic('labels.entity_consumption', { entity: t_generic('literals.charger_other'), titleize: true })} - ${t_generic('literals.phase')} 1`}
        onClick={() => handleToggleLine('l1')}
      />
      <LegendItem
        isToggled={visibleLines['l2']}
        color="bg-[#77A3FC]"
        label={`${t_generic('labels.entity_consumption', { entity: t_generic('literals.charger_other'), titleize: true })} - ${t_generic('literals.phase')} 2`}
        onClick={() => handleToggleLine('l2')}
      />
      <LegendItem
        isToggled={visibleLines['l3']}
        color="bg-[#FF9F8C]"
        label={`${t_generic('labels.entity_consumption', { entity: t_generic('literals.charger_other'), titleize: true })} - ${t_generic('literals.phase')} 3`}
        onClick={() => handleToggleLine('l3')}
      />
      <LegendItem
        isToggled={visibleLines['soc']}
        color="bg-[#E64997]"
        label={t('literals.state_of_charge', { titleize: true })}
        onClick={() => handleToggleLine('soc')}
      />
    </div>
  );
};
