import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import useChargerDetails from '@api/sites/chargers/use-charger-details';
import { ActiveSessions } from '@components/charger/session-history/ActiveSessions';
import { FinishedSessions } from '@components/charger/session-history/FinishedSessions';
import { TabLabel } from '@components/TabLabel';
import { getIsChargerConnected } from '@utils/charger-utils';

import { Typography } from '@destination/components';

export const SessionHistory = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'charger.charger_session'
  });

  const { id: siteId, chargerId } = useParams();
  const { charger } = useChargerDetails(siteId, chargerId);

  const name = charger?.name;

  return (
    <>
      {name && <TabLabel label={t('sessions_history', { name })} />}
      <Typography className="mb-6" variant="pageTitle">
        {t('sessions_history', { name })}
      </Typography>
      <div className="flex flex-col gap-12 rounded-md bg-white p-4">
        {charger && getIsChargerConnected(charger) && (
          <ActiveSessions siteId={siteId} chargerId={chargerId} />
        )}
        <FinishedSessions siteId={siteId} chargerId={chargerId} />
      </div>
    </>
  );
};
