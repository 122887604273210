import { usePortalTranslation } from '@hooks/use-portal-translation';

import { DetailLine } from '@components/DetailsLine';
import InfoIcon from '@icons/info_16.svg';

import { Tooltip, Typography } from '@destination/components';

export interface IChargingBudgetLimit {
  value: number;
  isDisabled: boolean;
}

export const ChargingBudgetLimit = ({
  value,
  isDisabled
}: IChargingBudgetLimit) => {
  const { t } = usePortalTranslation('siteSettings');

  const className = isDisabled ? 'text-[#BABABA]' : '';

  const infoIcon = (
    <Tooltip message="More info coming soon...">
      <InfoIcon data-testid="info-icon" className="[&>path]:fill-[#BABABA]" />
    </Tooltip>
  );

  const label = t('literals.charging_budget_limit', { titleize: true });

  return (
    <DetailLine icon={infoIcon} label={label} className={className}>
      <Typography variant="smallTitle" data-testid="charging-budget-limit">
        {value} A
      </Typography>
    </DetailLine>
  );
};
