import { useMemo } from 'react';

import { getTime } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import useSiteTelemetryCurrentDelivered from '@api/sites/use-site-telemetry-current-delivered';
import { Chargers } from '@components/sites/site/insights/Chargers';
import { CurrentDelivered } from '@components/sites/site/insights/CurrentDelivered';
import { CurrentDeliveredData } from '@components/sites/site/insights/CurrentDeliveredGraph';
import { DateTimeFilterButtons } from '@components/sites/site/insights/DateTimeFilterButtons';
import { EvseStatusOverview } from '@components/sites/site/insights/EvseStatusOverview';
import { useInsightsContext } from '@context/InsightsContext';
import { YAxisTickProvider } from '@context/YAxisTickContext';

import { Typography } from '@destination/components';

export const Insights = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'sites.site.insights' });

  const { id = '' } = useParams();

  const { range } = useInsightsContext();
  const { data, isLoading } = useSiteTelemetryCurrentDelivered(
    id,
    range[0].toISOString(),
    range[1].toISOString()
  );

  const currentDelivered: CurrentDeliveredData = useMemo(() => {
    if (data) {
      return data.map(item => ({
        ...item,
        timestamp: getTime(item.timestamp)
      }));
    }
    return [];
  }, [data]);

  return (
    <>
      <Typography className="mb-5" variant="sectionTitle">
        {t('title')}
      </Typography>
      <DateTimeFilterButtons isLoading={isLoading} />
      <div id="insights" className="flex flex-col gap-12">
        <YAxisTickProvider>
          <CurrentDelivered
            data={isLoading ? [] : currentDelivered}
            isLoading={isLoading}
          />
        </YAxisTickProvider>
        <EvseStatusOverview />
        <Chargers />
      </div>
    </>
  );
};
