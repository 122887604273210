import { DEVICES_BY_SITE_ID_PATH } from '@api/paths';
import { modifier } from '@utils/swr-utils';

import { ConnectDevice } from '@omnis-pulse-types';

export default function linkGatewayToSite(
  siteId: string,
  payload: ConnectDevice
) {
  const url = DEVICES_BY_SITE_ID_PATH(siteId);
  return modifier(url, 'PATCH', payload);
}
