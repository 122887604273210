import { usePortalTranslation } from '@hooks/use-portal-translation';

import useChargerFinishedSessions from '@api/sites/chargers/use-charger-finished-sessions';

import { DataError, Typography } from '@destination/components';

import { ChargerSessionTable } from '../ChargerSessionTable';

interface IFinishedSessions {
  siteId?: string;
  chargerId?: string;
}

export const FinishedSessions = ({ siteId, chargerId }: IFinishedSessions) => {
  const { t } = usePortalTranslation('chargers');

  const { sessions, isError, isLoading, mutate } = useChargerFinishedSessions(
    siteId,
    chargerId
  );

  if (isError) {
    return (
      <div>
        <Typography variant="smallTitle" className="mb-4">
          {t('literals.finished_sessions')}
        </Typography>
        <DataError onRefresh={mutate} />
      </div>
    );
  }

  return (
    <div>
      <Typography variant="smallTitle" className="mb-4">
        {t('literals.finished_sessions')}
      </Typography>
      <ChargerSessionTable sessions={sessions ?? []} isLoading={isLoading} />
    </div>
  );
};
