import { useCallback } from 'react';

import { usePortalTranslation } from '@hooks/use-portal-translation';
import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';

import { getToken } from '@api/api';
import { SITE_BY_ID_PATH } from '@api/paths';
import { axiosInstance, fetcher } from '@utils/swr-utils';

import { useAlert } from '@destination/components';

import type {
  SiteChargingStrategy,
  UpdateSiteChargingStrategy
} from '@omnis-pulse-types';

export type UseSiteChargingStrategyReturn = {
  data: SiteChargingStrategy | undefined;
  isLoading: boolean;
  isError: boolean;
  isUpdating: boolean;
  update: (payload: UpdateSiteChargingStrategy) => Promise<void>;
  mutate: () => Promise<SiteChargingStrategy | undefined>;
};

async function updateSiteChargingStrategy(
  url: string,
  { arg }: { arg: UpdateSiteChargingStrategy }
) {
  const token = await getToken();
  return axiosInstance.patch(url, arg, {
    headers: { Authorization: `Bearer ${token}` }
  });
}

export default function useSiteChargingStrategy(
  siteId?: string
): UseSiteChargingStrategyReturn {
  const { t } = usePortalTranslation();
  const { notify, notifyError } = useAlert();

  const successMsg = t('labels.save_settings_success', {
    entity: t('literals.charging_strategy'),
    titleize: true
  });
  const errorMsg = t('errors.failed_to_save_settings', {
    entity: t('literals.charging_strategy'),
    titleize: true
  });

  const { data, isLoading, error, mutate } = useSWR<SiteChargingStrategy>(
    siteId
      ? SITE_BY_ID_PATH(siteId) + '/load-management/charging-strategy'
      : null,
    fetcher
  );

  const { trigger, isMutating: isUpdating } = useSWRMutation(
    siteId
      ? SITE_BY_ID_PATH(siteId) + '/load-management/charging-strategy'
      : null,
    updateSiteChargingStrategy,
    {
      rollbackOnError: true,
      revalidate: true,
      onSuccess: () => {
        notify({ variant: 'success', header: successMsg });
      },
      onError: () => {
        notifyError({ header: errorMsg });
      }
    }
  );

  const update = useCallback(
    async (payload: UpdateSiteChargingStrategy) => {
      await trigger(payload);
      mutate();
    },
    [trigger, mutate]
  );

  return {
    data,
    isLoading,
    isError: Boolean(error),
    isUpdating,
    update,
    mutate
  };
}
