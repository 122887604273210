import { useTranslation } from 'react-i18next';

import { LegendItem } from '@components/charts/LegendItem';

export const EvseStatusChartLegend = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'sites.site.insights.evse_status_overview.legend'
  });

  return (
    <div className="ml-[94px] flex space-x-6">
      <LegendItem color="bg-[#99D794]" label={t('available')} />
      <LegendItem color="bg-[#9FBEF7]" label={t('charging')} />
      <LegendItem
        label={t('other_event')}
        style={{
          backgroundImage:
            'url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%226%22%20height%3D%226%22%20viewBox%3D%220%200%206%206%22%20fill%3D%22none%22%3E%3Cg%20clip-path%3D%22url(%23clip0_14633_76541)%22%3E%3Ccircle%20cx%3D%223%22%20cy%3D%223%22%20r%3D%223%22%20fill%3D%22%23FFD3A8%22/%3E%3Cpath%20d%3D%22M11.5%20-4L-3%2010.5%22%20stroke%3D%22%23FFA200%22/%3E%3Cpath%20d%3D%22M8.5%20-4L-6%2010.5%22%20stroke%3D%22%23FFA200%22/%3E%3C/g%3E%3Cdefs%3E%3CclipPath%20id%3D%22clip0_14633_76541%22%3E%3Crect%20width%3D%226%22%20height%3D%226%22%20rx%3D%223%22%20fill%3D%22white%22/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E")',
          backgroundSize: 'cover'
        }}
      />
      <LegendItem
        color="bg-[#99D794]"
        label={t('error')}
        style={{
          backgroundImage:
            "url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2IiBoZWlnaHQ9IjYiIHZpZXdCb3g9IjAgMCA2IDYiIGZpbGw9Im5vbmUiPjxjaXJjbGUgY3g9IjMiIGN5PSIzIiByPSIzIiBmaWxsPSIjRkZDOUNEIi8+PHBhdGggZD0iTTEuNzYyNyAxLjc2MjQ1TDQuMjM3NTcgNC4yMzczMiIgc3Ryb2tlPSIjRUYzOTM0Ii8+PHBhdGggZD0iTTQuMjM4MjggMS43NjI0NUwxLjc2MzQxIDQuMjM3MzIiIHN0cm9rZT0iI0VGMzkzNCIvPjwvc3ZnPg==')",
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center'
        }}
      />
    </div>
  );
};
