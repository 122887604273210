import { usePortalTranslation } from '@hooks/use-portal-translation';

import { DeviceStatus } from '@components/devices/DeviceStatus';
import { getConnectionStatusIcon } from '@utils/charger-utils';
import { dateTimeFormat, formatDate } from '@utils/date-utils';

import { ChargerDetails } from '@omnis-pulse-types';

interface IChargerConnectionStatus {
  charger: ChargerDetails;
}

export const ChargerConnectionStatus = ({
  charger
}: IChargerConnectionStatus) => {
  const { t } = usePortalTranslation();

  const label = t('literals.connection', { titleize: true });

  const { status, timestamp } = charger.connectionStatus;
  const icon = getConnectionStatusIcon(status);
  const value = t('states.' + status.toLowerCase(), {
    titleize: true
  });
  const formattedTimestamp = formatDate(timestamp, dateTimeFormat);
  const tooltip = `${t('literals.last_update', { titleize: true })} ${formattedTimestamp}`;

  return (
    <DeviceStatus
      data-testid="charger-connection-status"
      label={label}
      value={value}
      icon={icon}
      tooltip={tooltip}
    />
  );
};
