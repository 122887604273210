import useSWRInfinite from 'swr/infinite';

import { USER_ORGANIZATION_USERS_PATH } from '@api/paths';
import { defaultPageSize, hasReachedEnd } from '@utils/infinite-loading-utils';

export default function useOrganizationUsers(
  searchParams?: URLSearchParams,
  sortActive?: string,
  sortDirection?: 'ascending' | 'descending' | undefined,
  isPaginatedFetch = true
) {
  const params = new URLSearchParams(searchParams);

  if (sortDirection) {
    if (sortDirection === 'ascending') {
      params.append('sort', `${sortActive}:asc`);
    } else {
      params.append('sort', `${sortActive}:desc`);
    }
  }

  if (isPaginatedFetch) {
    params.append('pageSize', defaultPageSize);
  }

  if (searchParams?.toString().length) {
    searchParams.forEach(
      (value, key) => !params.get(key) && params.append(key, value)
    );
  }

  const {
    data: responses,
    error,
    isLoading,
    size: pageSize,
    setSize: setPageSize,
    mutate
  } = useSWRInfinite(
    index => {
      if (params.get('pageOffset')) {
        params.set('pageOffset', index.toString());
      } else {
        params.append('pageOffset', index.toString());
      }
      return `${USER_ORGANIZATION_USERS_PATH}?${params.toString()}`;
    },
    { revalidateFirstPage: false }
  );

  const handleLoadMore = () => {
    setPageSize(previousValue => previousValue + 1);
  };

  return {
    users: (responses ?? []).map(response => response.data).flat(),
    isLoading,
    isLoadingMore: responses ? responses.length < pageSize : false,
    isError: Boolean(error),
    hasReachedEnd: responses
      ? hasReachedEnd(responses, Number(defaultPageSize))
      : false,
    handleLoadMore,
    mutate
  };
}
