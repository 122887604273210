import { usePortalTranslation } from '@hooks/use-portal-translation';

import { DataError, InfiniteScroll, Typography } from '@destination/components';

import { EventLogRecord } from '@omnis-pulse-types';

import { LogsTable } from './LogsTable';

export interface ILogsContainer {
  isError: boolean;
  isLoadingInitial: boolean;
  isLoadingMore?: boolean;
  isCompleted?: boolean;
  logs: EventLogRecord[];
  onLoadMore?: () => void;
}

export const LogsContainer = ({
  isError,
  isLoadingInitial,
  isLoadingMore = false,
  isCompleted = true,
  logs,
  onLoadMore = () => undefined
}: ILogsContainer) => {
  const { t } = usePortalTranslation();

  if (isError) return <DataError />;

  if (isLoadingInitial === false && logs.length === 0) {
    return (
      <div className="flex items-center justify-center">
        <Typography>
          {t('labels.entity_not_found', {
            entity: t('literals.log_other')
          })}
        </Typography>
      </div>
    );
  }

  return (
    <InfiniteScroll
      onLoadMore={onLoadMore}
      isLoadingInitial={isLoadingInitial}
      isLoadingMore={isLoadingMore}
      isCompleted={isCompleted}
    >
      <LogsTable logs={logs} isLoading={isLoadingInitial} />
    </InfiniteScroll>
  );
};
