import { usePortalTranslation } from '@hooks/use-portal-translation';
import { timestamp } from '@pact-foundation/pact/src/v3/matchers';

import { Grid, TableLoading } from '@destination/components';

import { EventLogRecord } from '@omnis-pulse-types';

import { LogsTableRow } from './LogsTableRow';

export interface ILogsTable {
  logs: EventLogRecord[];
  isLoading: boolean;
}

export const LogsTable = ({ logs, isLoading }: ILogsTable) => {
  const { t } = usePortalTranslation();

  return (
    <>
      <Grid
        data-testid="logs-table"
        className="grid-cols-[auto_auto_auto_auto_auto]"
      >
        <Grid.HeadCell>{t('literals.timestamp')}</Grid.HeadCell>
        <Grid.HeadCell>{t('literals.level')}</Grid.HeadCell>
        <Grid.HeadCell>{t('literals.code')}</Grid.HeadCell>
        <Grid.HeadCell>{t('literals.source')}</Grid.HeadCell>
        <Grid.HeadCell>{t('literals.message')}</Grid.HeadCell>
        {isLoading === false &&
          logs.map((log, index) => (
            <LogsTableRow
              key={`${index}-${timestamp}`}
              index={index}
              log={log}
            />
          ))}
      </Grid>
      {isLoading && <TableLoading className="mt-3" />}
    </>
  );
};
