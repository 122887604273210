import useSWRInfinite from 'swr/infinite';

import { SITE_BY_ID_PATH } from '@api/paths';
import { buildSearchParams } from '@utils/build-search-parameters';
import { defaultPageSize, hasReachedEnd } from '@utils/infinite-loading-utils';
import { fetcher } from '@utils/swr-utils';

const useSiteEventLog = (
  siteId: string,
  searchParams?: URLSearchParams,
  isPaginatedFetch = true
) => {
  const params = buildSearchParams(searchParams, isPaginatedFetch);

  const {
    data: responses,
    error,
    isLoading,
    size: pageSize,
    setSize: setPageSize,
    mutate
  } = useSWRInfinite(
    index => {
      if (params.get('pageOffset')) {
        params.set('pageOffset', index.toString());
      } else {
        params.append('pageOffset', index.toString());
      }
      return `${SITE_BY_ID_PATH(siteId)}/events?${params.toString()}`;
    },
    fetcher,
    { revalidateFirstPage: false }
  );

  const handleLoadMore = () => {
    setPageSize(previousValue => previousValue + 1);
  };

  return {
    logs: (responses ?? []).map(response => response.data).flat(),
    isLoading,
    isLoadingMore: responses ? responses.length < pageSize : false,
    isError: Boolean(error),
    hasReachedEnd: responses
      ? hasReachedEnd(responses, Number(defaultPageSize))
      : false,
    handleLoadMore,
    mutate
  };
};

export default useSiteEventLog;
