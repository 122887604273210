import ChargingIcon from '@icons/charging_16.svg';
import CheckIcon from '@icons/check_16.svg';
import ErrorIcon from '@icons/error_16.svg';
import OccupiedIcon from '@icons/occupied_16.svg';
import OfflineIcon from '@icons/offline_16.svg';
import OnlineIcon from '@icons/online_16.svg';
import SuspendedIcon from '@icons/suspended_16.svg';
import WarningIcon from '@icons/warning_16.svg';

import {
  Charger,
  ChargerDetails,
  ConnectionStatus,
  ConnectionStatusEnum,
  EvseStatusEnum,
  EvseStatusTelemetry
} from '@omnis-pulse-types';

export const getEvseStatusIcon = (status: EvseStatusEnum) => {
  const testId = `${status.toLowerCase()}-connector-status-icon`;

  switch (status) {
    case EvseStatusEnum.AVAILABLE:
      return <CheckIcon data-testid={testId} className="text-[#0CA919]" />;
    case EvseStatusEnum.CHARGING:
      return <ChargingIcon data-testid={testId} />;
    case EvseStatusEnum.SUSPENDED:
      return <SuspendedIcon data-testid={testId} />;
    case EvseStatusEnum.OCCUPIED:
      return <OccupiedIcon data-testid={testId} />;
    case EvseStatusEnum.ERROR:
      return <ErrorIcon className="text-[#ff000f]" data-testid={testId} />;
    default:
      return <WarningIcon data-testid="unknown-connector-status-icon" />;
  }
};

export const getConnectionStatusIcon = (status: ConnectionStatusEnum) => {
  switch (status) {
    case ConnectionStatusEnum.CONNECTED:
      return (
        <OnlineIcon
          className="text-[#0CA919]"
          data-testid="connected-status-icon"
        />
      );
    case ConnectionStatusEnum.NOT_CONNECTED:
      return (
        <OfflineIcon
          className="text-[#BABABA]"
          data-testid="not-connected-status-icon"
        />
      );
    default:
      return <WarningIcon data-testid="unknown-status-icon" />;
  }
};

export const getIsChargerConnected = (charger: Charger | ChargerDetails) => {
  const {
    connectionStatus: { status }
  } = charger;
  return status === ConnectionStatusEnum.CONNECTED;
};

export const formatConnectorDataForStatusesGanttChart = (
  data: EvseStatusTelemetry[],
  evseIndex: number
) => {
  return data.map((entry, index) => {
    const nextDate =
      data.length - 1 === index
        ? new Date().toISOString()
        : data[index + 1].timestamp;
    return {
      x: new Date(entry.timestamp).valueOf(),
      z: new Date(nextDate).valueOf(),
      y: evseStatusToNumber[
        entry.statuses[evseIndex].status ?? EvseStatusEnum.UNKNOWN
      ]
    };
  });
};

export const formatConnectionDataForStatusesGanttChart = (
  data: ConnectionStatus[]
) => {
  return data.map((entry, index) => {
    const nextDate =
      data.length - 1 === index
        ? new Date().toISOString()
        : data[index + 1].timestamp!;
    return {
      x: new Date(entry.timestamp!).valueOf(),
      z: new Date(nextDate).valueOf(),
      y: connectionStatusToNumber[entry.status ?? EvseStatusEnum.UNKNOWN]
    };
  });
};

export const evseStatusToNumber: Record<EvseStatusEnum, number> = {
  UNKNOWN: 8,
  AVAILABLE: 0,
  CHARGING: 3,
  SUSPENDED: 3,
  ERROR: 6,
  OCCUPIED: 3
};

export const connectionStatusToNumber: Record<ConnectionStatusEnum, number> = {
  [ConnectionStatusEnum.CONNECTED]: 0,
  [ConnectionStatusEnum.NOT_CONNECTED]: 1,
  [ConnectionStatusEnum.UNKNOWN]: 2,
  [ConnectionStatusEnum.FAILED_TO_CONNECT]: 3,
  [ConnectionStatusEnum.CONNECTING]: 4
};

// DO not remove the comments, TailwindCSS JIT needs them to generate the classes
export const connectionStatusColors = [
  '#21A67A', // 'bg-[#21A67A]' - CONNECTED
  '#EF3934', // 'bg-[#EF3934]' - NOT_CONNECTED
  '#9F9F9F' // 'bg-[#9F9F9F]' - UNKNOWN
];

// DO not remove the comments, TailwindCSS JIT needs them to generate the classes
export const evseStatusColors = [
  '#21A67A', // 'bg-[#21A67A]' - AVAILABLE
  '#477A43', // 'bg-[#477A43]' - CONNECTING
  '#00ACEC', // 'bg-[#00ACEC]' - CONNECTED
  '#0052FF', // 'bg-[#0052FF]' - CHARGING
  '#000000', // 'bg-[#000000]' - SUSPENDED_EV
  '#FFA200', // 'bg-[#FFA200]' - SUSPENDED_EVSE
  '#EF3934', // 'bg-[#EF3934]' - ERROR
  '#CC50CC', // 'bg-[#CC50CC]' - FATAL_ERROR
  '#9F9F9F' // 'bg-[#9F9F9F]' - UNKNOWN
];
