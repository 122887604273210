import { createContext, useMemo } from 'react';

import { InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';

import { getToken } from '@api/api';
import fetchUserTokenHint from '@api/user-account/fetch-user-token-hint';
import { loginRequest, msalInstance } from '@config/authConfig';

export interface IUserContext {
  children: React.ReactNode;
}

export interface UserContextType {
  switchOrganization: (organizationOmnisId: string) => Promise<void>;
}

export const UserContext = createContext<UserContextType>({
  switchOrganization: async () => {}
});

export const UserContextProvider = ({ children }: IUserContext) => {
  const { inProgress } = useMsal();

  const initializeAndHandleRedirect = async () => {
    await msalInstance.initialize();
    const authResult = await msalInstance.handleRedirectPromise();

    if (authResult) await getToken(true);
  };

  const memoizedValue = useMemo(() => {
    const switchOrganization = async (
      organizationOmnisId: string
    ): Promise<void> => {
      const account = msalInstance.getAllAccounts()[0];
      const idTokenHint = await fetchUserTokenHint(organizationOmnisId);

      await msalInstance.acquireTokenRedirect({
        ...loginRequest,
        account: account,
        extraQueryParameters: { id_token_hint: idTokenHint.tokenHint }
      });
    };

    return { switchOrganization };
  }, []);

  initializeAndHandleRedirect();

  if (inProgress !== InteractionStatus.None) return <></>;

  return (
    <UserContext.Provider value={memoizedValue}>
      {children}
    </UserContext.Provider>
  );
};
