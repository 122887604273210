import { usePortalTranslation } from '@hooks/use-portal-translation';
import { KeyedMutator } from 'swr';

import ErrorIcon from '@icons/error_16.svg';
import LoadingIcon from '@icons/loading_16.svg';
import RefreshIcon from '@icons/refresh_16.svg';
import { ConvertWattToKilowatt } from '@utils/utils';

import { Grid, IconButton, Typography } from '@destination/components';

import {
  ChargerActiveSession,
  ConnectionStatus,
  EvseStatus
} from '@omnis-pulse-types';

import { ChargerCellContent } from './ChargerCellContent';

export interface IChargerPowerTableCell {
  connectionStatus: ConnectionStatus;
  evseStatuses: EvseStatus[];
  sessions?: ChargerActiveSession[];
  isLoading: boolean;
  isError: boolean;
  mutate: KeyedMutator<ChargerActiveSession[]>;
}

export const ChargerPowerTableCell = ({
  connectionStatus,
  evseStatuses,
  sessions,
  isLoading,
  isError,
  mutate
}: IChargerPowerTableCell) => {
  const { t_generic, t } = usePortalTranslation('chargers');

  if (isError) {
    const label = t_generic('labels.entity_could_not_load', {
      entity: t_generic('literals.data'),
      titleize: true
    });

    return (
      <Grid.DataCell className="grid content-center">
        <div className="z-10 flex items-center gap-1 pl-12">
          <ErrorIcon data-testid="error-icon" className="text-[#BABABA]" />
          <Typography variant="smallText" className="w-[260px]">
            {label}
          </Typography>
          <IconButton
            data-testid="refresh-icon-button"
            onClick={event => {
              event.stopPropagation();
              mutate(undefined, true);
            }}
          >
            <RefreshIcon />
          </IconButton>
        </div>
      </Grid.DataCell>
    );
  }

  const renderContent = (_evse: EvseStatus, session?: ChargerActiveSession) => {
    if (isLoading) {
      return (
        <LoadingIcon
          data-testid="loading-icon"
          className="animate-spin opacity-25"
        />
      );
    }

    if (session) {
      return (
        <Typography>{`${Math.round(ConvertWattToKilowatt(session.power))} kW`}</Typography>
      );
    }

    return (
      <Typography className="truncate">
        {t('not_charging', { titleize: true })}
      </Typography>
    );
  };

  return (
    <Grid.DataCell className="grid content-center">
      <ChargerCellContent
        connectionStatus={connectionStatus}
        evseStatuses={evseStatuses}
        sessions={sessions}
        render={renderContent}
      />
    </Grid.DataCell>
  );
};
