import useSWR from 'swr';

import { CHARGERS_BY_SITE_ID_PATH } from '@api/paths';

import { ChargerActiveSession } from '@omnis-pulse-types';

export default function useChargerActiveSessions(
  siteId?: string,
  chargerId?: string
) {
  let url = null;

  if (siteId && chargerId) {
    url = `${CHARGERS_BY_SITE_ID_PATH(siteId)}/${chargerId}/sessions/active-sessions`;
  }

  const { data, isLoading, error, mutate } =
    useSWR<ChargerActiveSession[]>(url);

  return {
    sessions: data,
    isLoading,
    isError: Boolean(error),
    mutate
  };
}
