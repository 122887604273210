import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';

import { subHours } from 'date-fns';

import { setStartAndEndTimes } from '@utils/insights-utils';

type TimeRange = [Date, Date] | [undefined, undefined];

export interface InsightsContextType {
  date: Date;
  timeRange: [Date, Date] | [undefined, undefined];
  timeRangePickerKey: number;
  range: [Date, Date];
  setDate: Dispatch<SetStateAction<Date>>;
  setTimeRange: Dispatch<SetStateAction<TimeRange>>;
  setTimeRangePickerKey: Dispatch<SetStateAction<number>>;
  handleZoom: (refAreaLeft: number, refAreaRight: number) => void;
  handleResetZoom: () => void;
}

const InsightsContext = createContext<InsightsContextType | null>(null);

interface InsightsProviderProps {
  children: ReactNode;
  initialTimeRange?: TimeRange; // New prop for initial time range
}

export const InsightsProvider = ({
  children,
  initialTimeRange
}: InsightsProviderProps) => {
  const [date, setDate] = useState(new Date());

  const now = new Date();
  const defaultRange: TimeRange = [subHours(now, 4), now];

  const [timeRange, setTimeRange] = useState<TimeRange>(
    initialTimeRange || defaultRange
  );

  const [timeRangePickerKey, setTimeRangePickerKey] = useState(0);

  const handleZoom = useCallback((left: number, right: number) => {
    if (left > right) {
      [left, right] = [right, left];
    }
    setTimeRange([new Date(left), new Date(right)]);
    setTimeRangePickerKey(previousValue => previousValue + 1);
  }, []);

  const handleResetZoom = useCallback(() => {
    const now = new Date();
    setTimeRange([subHours(now, 4), now]);
    setTimeRangePickerKey(previousValue => previousValue + 2);
  }, []);

  const range: [Date, Date] = useMemo(() => {
    const now = new Date();
    if (timeRange[0] === undefined && timeRange[1] === undefined) {
      return [subHours(now, 4), now]; // Default range is the last 4 hours
    }
    return setStartAndEndTimes(date, timeRange);
  }, [timeRange, date]);

  const contextValue = useMemo(() => {
    return {
      date,
      timeRange,
      timeRangePickerKey,
      range,
      setDate,
      setTimeRange,
      setTimeRangePickerKey,
      handleZoom,
      handleResetZoom
    };
  }, [date, timeRange, timeRangePickerKey, range, handleZoom, handleResetZoom]);

  return (
    <InsightsContext.Provider value={contextValue}>
      {children}
    </InsightsContext.Provider>
  );
};

export const useInsightsContext = (startTime?: Date, endTime?: Date) => {
  const context = useContext(InsightsContext);

  if (!context) {
    throw new Error(
      'useInsightsContext must be used within a InsightsProvider'
    );
  }

  const { setTimeRange } = context;

  useEffect(() => {
    if (startTime && endTime) {
      setTimeRange([startTime, endTime]);
    }
  }, [startTime, endTime, setTimeRange]);

  return context;
};
