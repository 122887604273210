import { createContext, ReactNode, useContext, useMemo } from 'react';

import { useParams } from 'react-router-dom';

import useSiteDevices from '@api/sites/use-site-devices';

import { DataError, LoadingSkeleton } from '@destination/components';

import { Device } from '@omnis-pulse-types';

interface DeviceData {
  hasCharger: boolean;
  hasGridMeter: boolean;
  hasGateway: boolean;
  hasNoDevices: boolean;
  chargers: Device[];
  gateways: Device[];
  gridMeters: Device[];
}

interface SiteDevicesContextType {
  deviceData: DeviceData;
  isLoading: boolean;
  isError: boolean;
}

const SiteDevicesContext = createContext<SiteDevicesContextType | null>(null);

export const useSiteDevicesContext = () => {
  const context = useContext(SiteDevicesContext);
  if (!context) {
    throw new Error(
      'useSiteDevicesContext must be used within a SiteDevicesProvider'
    );
  }
  return context;
};

interface SiteDevicesProviderProps {
  children: ReactNode;
}

export const SiteDevicesProvider = ({ children }: SiteDevicesProviderProps) => {
  const { id } = useParams();
  const { devices, isLoading, isError, mutate } = useSiteDevices(id);

  const contextValue = useMemo(() => {
    let deviceData: DeviceData = {
      hasCharger: false,
      hasGridMeter: false,
      hasGateway: false,
      hasNoDevices: true,
      chargers: [],
      gridMeters: [],
      gateways: []
    };

    if (devices) {
      const hasCharger = devices.chargers.length > 0;
      const hasGridMeter = devices.gridMeters.length > 0;
      const hasGateway = devices.gateways.length > 0;
      const hasNoDevices = !hasCharger && !hasGridMeter && !hasGateway;

      deviceData = {
        hasCharger,
        hasGridMeter,
        hasGateway,
        hasNoDevices,
        chargers: devices.chargers,
        gateways: devices.gateways,
        gridMeters: devices.gridMeters
      };
    }

    return { deviceData, isLoading, isError };
  }, [devices, isLoading, isError]);

  if (isError) {
    return (
      <div className="flex h-full w-full items-center">
        <DataError
          variant="primary"
          onRefresh={() => mutate(undefined, true)}
        />
      </div>
    );
  }
  if (isLoading) {
    return <LoadingSkeleton className="!h-full" height={0} />;
  }

  return (
    <SiteDevicesContext.Provider value={contextValue}>
      {children}
    </SiteDevicesContext.Provider>
  );
};
