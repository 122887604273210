import { config, msalInstance } from '@config/authConfig';

export async function getToken(forceRefresh = false) {
  const currentAccount = msalInstance.getAllAccounts()[0];
  if (currentAccount) {
    const accessTokenRequest = {
      scopes: [config['scope']],
      account: currentAccount,
      forceRefresh
    };
    const accessTokenResponse = await msalInstance
      .acquireTokenSilent(accessTokenRequest)
      .catch(async () => {
        // Call logoutRedirect in case of acquireTokenSilent failure
        await msalInstance.logoutRedirect();
      });
    return accessTokenResponse?.accessToken;
  }
}
