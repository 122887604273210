import { useLayoutEffect, useMemo, useState } from 'react';

import { getTime } from 'date-fns';
import { useTranslation } from 'react-i18next';
import {
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';

import { CustomCursor } from '@components/charts/CustomCursor';
import { CustomTooltip } from '@components/charts/CustomTooltip';
import { CustomYAxisTick } from '@components/charts/CustomYAxisTick';
import { LegendItem } from '@components/charts/LegendItem';
import { useYAxisTickContext } from '@context/YAxisTickContext';
import { generateXAxisTicks } from '@utils/chart-utils';
import { formatDate, timeFormat } from '@utils/date-utils';

import { CurrentTelemetry } from '@omnis-pulse-types';

export interface ISiteOverviewChart {
  data: CurrentTelemetry;
}

type DataKey = 'currentL1' | 'currentL2' | 'currentL3' | 'gridLimit';
type VisibilityState = Record<DataKey, boolean>;

export const SiteOverviewChart = ({ data }: ISiteOverviewChart) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'sites.site.insights.graph'
  });

  const [visibleLines, setVisibleLines] = useState<VisibilityState>({
    currentL1: true,
    currentL2: true,
    currentL3: true,
    gridLimit: true
  });

  const handleToggleLine = (key: string) => {
    setVisibleLines(prev => ({
      ...prev,
      [key]: !prev[key as DataKey]
    }));
  };

  const { clearCollection } = useYAxisTickContext();
  useLayoutEffect(() => {
    // Clear the collection every time the data changes so that the Y-axis min/max values are recalculated
    clearCollection();
  }, [data, clearCollection]);

  const seriesOfGridLimits = useMemo(() => {
    return (data.maxCurrent || [])
      .filter(entry => entry.timestamp)
      .map(entry => ({
        gridLimit: entry.value,
        timestamp: getTime(new Date(entry.timestamp))
      }));
  }, [data]);

  const seriesOfConsumptionValues = useMemo(() => {
    return (data.current || [])
      .filter(entry => entry.timestamp)
      .map(entry => ({
        ...entry,
        timestamp: getTime(new Date(entry.timestamp))
      }));
  }, [data]);

  return (
    <div className="flex h-full flex-col">
      <div className="relative flex-auto">
        <ResponsiveContainer height="100%" width="100%">
          <ComposedChart>
            <CartesianGrid strokeDasharray="4 4" stroke="#EBEBEB" />
            <XAxis
              dataKey="timestamp"
              tickLine={false}
              tick={{ fill: '#9F9F9F', fontSize: 8, fontFamily: 'ABBvoice' }}
              stroke="#ebebeb"
              interval="preserveStartEnd"
              tickFormatter={tick => formatDate(tick, timeFormat)}
              scale="time"
              type="number"
              domain={['auto', 'auto']}
              ticks={generateXAxisTicks()}
            />
            <YAxis
              orientation="right"
              tickLine={false}
              tickFormatter={value => `${value} A`}
              tick={<CustomYAxisTick fontSize={8} />}
              stroke="#ebebeb"
              type="number"
              width={45}
              padding={{ top: 16 }} // Important for the Tooltip to be positioned correctly
            />
            <Tooltip
              allowEscapeViewBox={{ x: true, y: true }}
              offset={0}
              isAnimationActive={false}
              content={<CustomTooltip />}
              cursor={
                seriesOfGridLimits || seriesOfConsumptionValues ? (
                  <CustomCursor />
                ) : (
                  <></>
                )
              }
            />
            <Line
              type="linear"
              dataKey="currentL1"
              stroke="black"
              dot={false}
              connectNulls={true}
              data={seriesOfConsumptionValues}
              hide={visibleLines['currentL1'] === false}
              isAnimationActive={false}
              unit="A"
            />
            <Line
              type="linear"
              dataKey="currentL2"
              stroke="#2A35FF"
              dot={false}
              connectNulls={true}
              data={seriesOfConsumptionValues}
              hide={visibleLines['currentL2'] === false}
              isAnimationActive={false}
              unit="A"
            />
            <Line
              type="linear"
              dataKey="currentL3"
              stroke="#E64997"
              dot={false}
              connectNulls={true}
              data={seriesOfConsumptionValues}
              hide={visibleLines['currentL3'] === false}
              isAnimationActive={false}
              unit="A"
            />
            {seriesOfGridLimits.length > 0 && (
              <Line
                type="stepAfter"
                dataKey="gridLimit"
                stroke="#00ACEC"
                dot={false}
                connectNulls={true}
                data={seriesOfGridLimits}
                hide={visibleLines['gridLimit'] === false}
                isAnimationActive={false}
                unit="A"
              />
            )}
          </ComposedChart>
        </ResponsiveContainer>
      </div>
      <div className="flex-initial">
        <div className="ml-1.5 flex gap-6">
          {data.maxCurrent && data.maxCurrent.length > 0 && (
            <LegendItem
              isToggled={visibleLines['gridLimit']}
              label={t('gridLimit')}
              color="bg-[#00ACEC]"
              onClick={() => handleToggleLine('gridLimit')}
            />
          )}
          <LegendItem
            isToggled={visibleLines['currentL1']}
            label={t('currentL1')}
            color="bg-black"
            onClick={() => handleToggleLine('currentL1')}
          />
          <LegendItem
            isToggled={visibleLines['currentL2']}
            label={t('currentL2')}
            color="bg-[#2A35FF]"
            onClick={() => handleToggleLine('currentL2')}
          />
          <LegendItem
            isToggled={visibleLines['currentL3']}
            label={t('currentL3')}
            color="bg-[#E64997]"
            onClick={() => handleToggleLine('currentL3')}
          />
        </div>
      </div>
    </div>
  );
};
