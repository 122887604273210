import { usePortalTranslation } from '@hooks/use-portal-translation';

import useChargerActiveSessions from '@api/sites/chargers/use-charger-active-sessions';

import { DataError, Typography } from '@destination/components';

import { ChargerSessionTable } from '../ChargerSessionTable';

interface IActiveSessions {
  siteId?: string;
  chargerId?: string;
}

export const ActiveSessions = ({ siteId, chargerId }: IActiveSessions) => {
  const { t } = usePortalTranslation('chargers');

  const { sessions, isLoading, isError, mutate } = useChargerActiveSessions(
    siteId,
    chargerId
  );

  if (isLoading || (sessions && sessions.length === 0)) {
    return null;
  }

  if (isError) {
    return (
      <div>
        <Typography variant="smallTitle" className="mb-4">
          {t('literals.ongoing_sessions')}
        </Typography>
        <DataError onRefresh={mutate} />
      </div>
    );
  }

  return (
    <div>
      <Typography variant="smallTitle" className="mb-4">
        {t('literals.ongoing_sessions')}
      </Typography>
      <ChargerSessionTable sessions={sessions ?? []} isLoading={false} />
    </div>
  );
};
