import { DataPoint } from '@components/charts/HorizontalBarChart';

export type ProcessedDataPoint = {
  x: number;
  y: string;
  z: number;
  status: string;
};

export type YAxisTick = {
  label: string;
  y: number;
};

export type RenderCustomYAxisTickProps = {
  payload: {
    value: string;
  };
  x: number;
  y: number;
  width: number;
  height: number;
};

export const calculateYAxisTicks = (data: ProcessedDataPoint[]) => {
  const spacingBetweenEvse = 30;
  const spacingBetweenChargerGroup = 48;
  const spacingBetweenChargerAndEvse = 28;
  const startPixel = 28;

  const yAxisTicks: YAxisTick[] = [];

  const uniqueLabels: string[] = [
    ...new Set(data.map((datapoint: { y: string }) => datapoint.y))
  ];

  uniqueLabels.forEach((label, index) => {
    if (index === 0) {
      yAxisTicks.push({ label: label, y: startPixel });
    } else {
      const previousLabel = yAxisTicks[index - 1].label;
      const isEVSELabel = label.includes('SUBGROUP');
      let spacing = 0;

      if (!isEVSELabel) {
        spacing = spacingBetweenChargerGroup;
      } else if (isEVSELabel && previousLabel.includes('SUBGROUP')) {
        spacing = spacingBetweenEvse;
      } else {
        spacing = spacingBetweenChargerAndEvse;
      }

      yAxisTicks.push({
        label: label,
        y: yAxisTicks[index - 1].y + spacing
      });
    }
  });

  return yAxisTicks;
};

export const processData = (data: DataPoint[]): ProcessedDataPoint[] => {
  const processedData: ProcessedDataPoint[] = [];
  const uniqueGroups = [...new Set(data.map(d => d.chargerName))];

  uniqueGroups.forEach(group => {
    processedData.push({
      x: data[0].x,
      y: `GROUP_${group}`,
      z: 0,
      status: 'GroupLabel'
    });

    const groupData = data
      .filter(d => d.chargerName === group)
      .sort((a, b) => a.subgroup - b.subgroup)
      .map(point => ({
        x: point.x,
        y: `GROUP_${point.group}_SUBGROUP_${point.subgroup}`,
        z: point.z,
        status: point.status.toString()
      }));

    processedData.push(...groupData);
  });

  return processedData;
};
