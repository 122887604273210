import { useParams } from 'react-router-dom';

import useSiteLoadManagementLimits from '@api/sites/use-site-load-management-limits';

import { DataError, LoadingSkeleton } from '@destination/components';

import { ActiveLoadManagementMode } from '@omnis-pulse-types';

import { GridConnectionLimit } from '../GridConnectionLimit';
import { FallbackChargingBudgetLimit } from './FallbackChargingBudgetLimit';

export const DynamicLoadManagement = () => {
  const { id: siteId } = useParams();
  const { data, isLoading, isError, mutate, isUpdating, update } =
    useSiteLoadManagementLimits(siteId);

  if (!data) return null;

  const isDisabled =
    isUpdating || data.activeMode === ActiveLoadManagementMode.STATIC;

  const renderContent = () => {
    if (isError) {
      return (
        <DataError
          onRefresh={() => {
            mutate();
          }}
        />
      );
    }
    if (isLoading) {
      return <LoadingSkeleton height={20} />;
    }

    return (
      <div className="mt-2">
        <div className="flex min-h-[56px] rounded-l-sm rounded-r-sm bg-[#FAFAFA] px-3 py-2">
          <GridConnectionLimit
            value={data.dynamicGridConnectionLimit}
            activeMode={ActiveLoadManagementMode.DYNAMIC}
            isDisabled={isDisabled}
            onSave={update}
          />
        </div>
        <div className="flex min-h-[56px] rounded-l-sm rounded-r-sm px-3 py-2">
          <FallbackChargingBudgetLimit
            value={data.fallbackChargingBudgetLimit}
            maxValue={data.dynamicGridConnectionLimit}
            isDisabled={isDisabled}
            onSave={update}
          />
        </div>
      </div>
    );
  };

  return renderContent();
};
