import { ComponentProps } from 'react';

import { usePortalTranslation } from '@hooks/use-portal-translation';

import { Card, RadioGroup, Typography } from '@destination/components';

import { ChargingStrategy as ChargingStrategyEnum } from '@omnis-pulse-types';

type Options = ComponentProps<typeof RadioGroup>['options'];

const strategies = [
  ChargingStrategyEnum.EQUAL_POWER,
  ChargingStrategyEnum.EQUAL_SHARE,
  ChargingStrategyEnum.FIFO
];

export const ChargingStrategySelector = ({
  value,
  onChange,
  disabled = false
}: {
  value: ChargingStrategyEnum | null;
  onChange: (value: ChargingStrategyEnum) => void;
  disabled?: boolean;
}) => {
  const { t, t_generic } = usePortalTranslation('siteSettings');

  // Generate the options dynamically based on the strategy enum
  const options: Options = strategies.map(strategy => ({
    label: t(`strategy.${strategy.toLowerCase()}`, { titleize: true }),
    description: t(`strategy.${strategy.toLowerCase()}_description`, {
      titleize: true
    }),
    value: strategy
  }));

  const header = t_generic('labels.entity_strategy', {
    entity: t_generic('literals.charging'),
    titleize: true
  });

  return (
    <Card className="mb-2 flex w-[620px] flex-col">
      <Typography variant="smallTitle" className="mb-4">
        {header}
      </Typography>
      <RadioGroup
        value={value}
        options={options}
        disabled={disabled}
        onChange={val => onChange(val as ChargingStrategyEnum)}
      />
    </Card>
  );
};
