import { ReactNode, useContext } from 'react';

import { usePortalTranslation } from '@hooks/use-portal-translation';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { FooterNavigation } from '@components/navigation/FooterNavigation';
import { MenuNavigation } from '@components/navigation/MenuNavigation';
import { SiteBreadcrumbs } from '@components/sites/SiteBreadcrumbs';
import { UserDetails } from '@components/user/UserDetails';
import { UnsupportedViewportDisclaimer } from '@components/utils/UnsupportedViewportDisclaimer';

import {
  MobileNavigation,
  Sidebar,
  SidebarContext,
  Typography
} from '@destination/components';

export const Root = () => {
  const navigate = useNavigate();

  const { isCollapsed } = useContext(SidebarContext);

  // base class names
  const baseClasses =
    'flex grow flex-col overflow-auto pb-[102px] pt-[9px] transition-all duration-200 min-h-screen';
  // small screen class names
  const smallScreenClasses = 'px-8';
  // large screen class names
  const largeScreenClasses =
    'large-screen:pl-[calc((100vw-1560px)/2)] large-screen:pr-[calc((100vw-1560px)/2)]';
  // collapsed class names
  const collapsedClasses = isCollapsed
    ? 'small-screen:pl-[72px] small-screen:pr-8'
    : 'small-screen:pl-[220px] small-screen:pr-[140px]';

  return (
    <div data-testid="page-content" className="flex h-full">
      <div className="hidden small-screen:block">
        <Sidebar
          menuItems={<MenuNavigation isMobile={false} />}
          footerItems={<FooterNavigation isMobile={false} />}
          onClickBranding={() => navigate('/')}
        />
      </div>
      <main
        id="main-content"
        className={`${baseClasses} ${smallScreenClasses} ${largeScreenClasses} ${collapsedClasses}`}
      >
        <UnsupportedViewportDisclaimer />
        <MobileTop />
        <DefaultTop />
        <Outlet />
      </main>
    </div>
  );
};

const MobileTop = () => {
  return (
    <div className="grid grid-cols-2 pb-4 small-screen:hidden">
      <div className="my-auto -ml-2 flex">
        <MobileNavigation
          navigationItems={
            <>
              <MenuNavigation isMobile={true} />
              <FooterNavigation isMobile={true} />
            </>
          }
        />
      </div>
      <div className="ml-auto flex h-10">
        <UserDetails />
      </div>
    </div>
  );
};

const DefaultTop = () => {
  const { t } = usePortalTranslation();
  const { pathname } = useLocation();

  const [rootUrl, ...otherPathSegments] = pathname.split('/').filter(Boolean);

  let breadcrumbs: ReactNode = null;
  if (rootUrl === 'sites') breadcrumbs = <SiteBreadcrumbs />;

  return (
    <div className="hidden grid-cols-3 pb-4 small-screen:grid">
      {otherPathSegments.length !== 0 ? breadcrumbs : <div className="block" />}
      <div className="flex h-8">
        <Typography className="m-auto !leading-4" variant="smallTitle">
          {t('application_name')}
        </Typography>
      </div>
      <div className="ml-auto flex h-10">
        <UserDetails />
      </div>
    </div>
  );
};
