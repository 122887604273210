import { usePortalTranslation } from '@hooks/use-portal-translation';

import SiteIcon from '@icons/dashboard_16.svg';
import UsersIcon from '@icons/drivers_16.svg';

import { MobileNavigation, Navigation } from './Navigation';

interface MenuNavigation {
  isMobile: boolean;
}

export const MenuNavigation = ({ isMobile }: MenuNavigation) => {
  const { t, t_generic } = usePortalTranslation('accessManagement');

  const items = [
    {
      id: 'sites',
      icon: <SiteIcon />,
      label: t_generic('literals.site_other', { titleize: true }),
      url: '/sites'
    },
    {
      id: 'access-management',
      icon: <UsersIcon />,
      label: t_generic('labels.entity_management', {
        entity: t('literals.access'),
        titleize: true
      }),
      url: '/access-management'
    }
  ];

  if (isMobile) {
    return <MobileNavigation items={items} />;
  }

  return <Navigation items={items} />;
};
