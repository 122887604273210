import useSWR from 'swr';

import { SITE_BY_ID_PATH } from '@api/paths';

import { CurrentTelemetry } from '@omnis-pulse-types';

export function useSiteGridMeterTelemetry(
  siteId?: string,
  searchParams?: URLSearchParams
) {
  let url = null;

  if (siteId) {
    const params = new URLSearchParams(searchParams);

    url = SITE_BY_ID_PATH(siteId) + '/grid-meter/telemetry/current';
    if (params.toString().length) {
      url = `${url}?${params.toString()}`;
    }
  }

  const { data, isLoading, error, mutate } = useSWR<CurrentTelemetry>(url);

  const isNotFound = error?.status === 404;

  if (isNotFound) {
    return {
      data: undefined,
      isLoading: false,
      isError: false,
      isNotFound,
      mutate
    };
  }

  return { data, isLoading, isError: Boolean(error), isNotFound, mutate };
}

export default useSiteGridMeterTelemetry;
