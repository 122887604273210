import { usePortalTranslation } from '@hooks/use-portal-translation';

import { Card, Typography } from '@destination/components';

import {
  CurrentDeliveredGraph,
  ICurrentDelivered
} from './CurrentDeliveredGraph';

export const CurrentDelivered = ({ data, isLoading }: ICurrentDelivered) => {
  const { t } = usePortalTranslation('chargers');

  return (
    <div>
      <Typography className="mb-4" variant="smallTitle">
        {t('literals.current_delivered', { titleize: true })}
      </Typography>
      <Card>
        <CurrentDeliveredGraph data={data} isLoading={isLoading} />
      </Card>
    </div>
  );
};
