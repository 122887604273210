import { useParams } from 'react-router-dom';

import updatePeakCapacity from '@api/sites/update-site-energy-management-peak-capacity';
import useSiteEnergyManagementPeakCapacity from '@api/sites/use-site-energy-management-peak-capacity';

import { Card, DataError, LoadingSkeleton } from '@destination/components';

import { UpdateSitePeakCapacity } from '@omnis-pulse-types';

import { PeakCapacityPower } from './PeakCapacityPower';
import { PeakCapacitySwitch } from './PeakCapacitySwitch';
import { PeakCapacityTimeResolution } from './PeakCapacityTimeResolution';

export const PeakCapacity = () => {
  const { id } = useParams();
  const { data, isLoading, isError, isNotFound, mutate } =
    useSiteEnergyManagementPeakCapacity(id);

  const handleSave = async (payload: UpdateSitePeakCapacity) => {
    if (id) {
      const { error } = await updatePeakCapacity(id, payload);
      if (error) throw new Error();
      await mutate();
    }
  };

  const renderContent = () => {
    if (isLoading) {
      return <LoadingSkeleton height={20} />;
    }

    if (isError) {
      return <DataError onRefresh={() => mutate(undefined, true)} />;
    }

    if (data === undefined) {
      return null;
    }

    return (
      <>
        <div className="flex items-center gap-4 rounded-[4px] bg-[#FAFAFA] px-3 py-4">
          <PeakCapacitySwitch isEnabled={data.isEnabled} onSave={handleSave} />
        </div>
        <div className="flex flex-col gap-4 px-3 py-4">
          <PeakCapacityPower power={data.power} onSave={handleSave} />
        </div>
        <div className="flex flex-col gap-4 bg-[#FAFAFA] px-3 py-4">
          <PeakCapacityTimeResolution
            timePeriod={data.timePeriod}
            onSave={handleSave}
          />
        </div>
      </>
    );
  };

  if (isNotFound) {
    return null;
  }

  return (
    <Card data-testid="peak-capacity-card" className="w-[620px]">
      {renderContent()}
    </Card>
  );
};
